import { Injectable } from '@angular/core';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  beforesecureKeyValue: string;
  beforesecureIVKeyValue: string;
  afterescureKeyValue: string;
  afterescureIVKeyValue: string;
  checkDecyptFun: boolean = false
  dataAllEncyptionCheck: boolean;

  constructor(private aes256: AES256, public platform: Platform) {

  }

  async encryptFunc(data, typeEncyptoin) {
    //console.log('Encryption_Keys ==>', this.beforesecureKeyValue, this.beforesecureIVKeyValue);
    //console.log('Encrypt_Data ==>', data, typeEncyptoin);
    return new Promise((resolve, reject) => {
      switch (typeEncyptoin) {
        case 'anonymousKey':
          this.aes256.encrypt(this.beforesecureKeyValue, this.beforesecureIVKeyValue, data).then(res => {
            if (res) {
              resolve(res);
            } else {
              reject(false);
            }
          });
          break;
        case 'authenticatekey':
          this.aes256.encrypt(this.afterescureKeyValue, this.afterescureIVKeyValue, data).then(res => {
            if (res) {
              resolve(res);
            } else {
              reject(false);
            }
          });
          break;
        default:
          break;
      }
    });
  }


  async decryptFunc(data, type) {
    //console.log('Decrypt_Data ==>',data,type);
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        switch (type) {
          case 'anonymousKey':
            //console.log("decryption  beforesecureKeyValue" + type)
            ////console.log(storageKeys.authenticatekey)
            //console.log("this.beforesecureKeyValue==>>>" + this.beforesecureKeyValue)
            ////console.log("this.beforesecureIVKeyValue==>>>" + this.beforesecureIVKeyValue)
            this.aes256.decrypt(this.beforesecureKeyValue, this.beforesecureIVKeyValue, data).then(async res => {
              ////console.log('decryption_Succ beforesecureKeyValue => ', res)

              if (res) {

                var result = JSON.parse(res);

                resolve(result);
                this.dataAllEncyptionCheck = true

              } else {
                reject(false);
              }
            }).catch(err => {
              ////console.log('decryptionErr => ', err);
              reject(err);
            });
            break;


          case 'authenticatekey':
            if (this.checkDecyptFun == true) {

              this.aes256.decrypt(this.afterescureKeyValue, this.afterescureIVKeyValue, data).then(async res => {
                if (res) {
                  var result = JSON.parse(res);
                  resolve(result);
                } else {
                  reject(false);
                }
              }).catch(err => {
                ////console.log('decryptionErr => ', err);
                reject(err);
              });
              break;
            }
          default:
            break;
        }
      })
    }
  }

  // authenticatekeyApi() {
  //   this.httpWrapper.get(`program/authenticatekey`).subscribe(
  //     (res) => {
  //       ////console.log("what authticateApi ress==>>"+ JSON.stringify(res))

  //       this.afterescureKeyValue = res.secureKey;
  //       this.afterescureIVKeyValue = res.secureIV;

  //       this.checkDecyptFun = true
  //     },error => {
  //       ////console.log("authenticatekeyApi_error: ", error);
  //     });
  //   }

}
