import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { 
    console.log("i am from app qr");
  }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  barCodeScanner() {
    console.log("barcode scanner");
    this.modalCtrl.dismiss({
      "barCodeShow": true
    })
  }
}
