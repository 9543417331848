export class Item {
    menuId: any;
    posCode: any;
    itemName: any;
    sequenceId: any;
    deliveryCheck: any;
    takeAwayCheck: any;
    roomDiningCheck: any;
    quickDelivery: any;
    widgetDetails: any;
    itemDescription: any;
    itemImageUrl: any;
    serves: any;
    lowCalorie: any;
    veg: any;
    nutritionValue: any;
    pricePerItem: any;
    goesWell: any;
    descriptionTags: any;
    constructor(attributes: Object = {}) {
        Object.assign(this, attributes);
    }
}

export class ItemList {
    items: Array<Item> = [];
    constructor(attributes: Object = {}) {
        Object.assign(this, attributes);
    }
}