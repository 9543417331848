import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {
  @Input('formController') formController: any;
  @Input('errorMessageObject') errorMessageObject: any;
  @Input('fieldName') fieldName: any;
  @Input('isNoPadding') isNoPadding: any;
  @Input('isFormSubmitted') isFormSubmitted: any;
  constructor(
    public commonService: CommonService,
    public translateService: TranslateService
  ) { 
  }

  ngOnInit() {
  }

  getValidationMessages() {
    switch(this.errorMessageObject) {
      case 'setupRules':
        return this.translateService.instant('setupRules.errors.' + this.fieldName);
      case 'setupMenu':
        return this.translateService.instant('setupMenu.errors.' + this.fieldName);
      case 'nutrition':
        return this.commonService.nutritionTranslation[this.fieldName];
      case 'revenue.fullMenu':
        return this.translateService.instant('revenue.fullMenu.errors.' + this.fieldName);
      case 'revenue.menuWidget':
          return this.translateService.instant('revenue.menuWidget.errors.' + this.fieldName);
      case 'revenue.byDelivery':
        return this.translateService.instant('revenue.byDelivery.errors.' + this.fieldName);
      case 'blockServices':
        return this.translateService.instant('blockServices.errors.' + this.fieldName);
    }
  }

}
