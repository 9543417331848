import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { ProfileService } from 'src/app/services/profile.service';
import { REGEX } from 'src/app/app.constants';
import { PHONECOUNTRY } from 'src/app/app.constants';
import { EDITPROFILE_MODAL,JAVASCRIPT ,EDIT_EMAIL_MOBILE_MODAL} from 'src/app/app.constants';



declare var $: any
@Component({
    selector: 'app-edit-email-mobile',
    templateUrl: './edit-email-mobile.component.html',
    styleUrls: ['./edit-email-mobile.component.scss'],
})
export class EditEmailMobileComponent implements OnInit {


    countries: any = [];
    editForm: FormGroup;
    editDataValue: any;
    emailFeildShow: boolean = false
    phoneFeildShow: boolean = false;
    editProfilePage = EDIT_EMAIL_MOBILE_MODAL.EDIT_PROFILE;
    SelectCode: any;
    editstoreData: any = {};
    employeeDetails: any;
    countryCode: any;
    updateEditProfileEnable: boolean;
    editUpdateProfileValue: boolean = true;

    constructor(
        private modalCtrl: ModalController,
        private commonService: CommonService,
        navParams: NavParams,
        public appState: AppStateService,
        private utilities: UtilitiesService,
        private profileService: ProfileService) {

        this.editDataValue = navParams.get(EDIT_EMAIL_MOBILE_MODAL.VALUE_EDIT_DETAILS)
        this.employeeDetails = navParams.get(EDIT_EMAIL_MOBILE_MODAL.DETAILS_EMP)
        this.countryCode = navParams.get(EDIT_EMAIL_MOBILE_MODAL.COUNTRY_CODE)
        this.formValid()
    }

    ngOnInit() {
        this.editstoreData = this.appState.getAppData();
        ////console.log("this is the appp state data edit -=-=-=-=-=-=-=",this.editstoreData);
        this.countryCodeFun()
        this.funChekTypeEdit()
    }
    funChekTypeEdit() {
        if (this.editDataValue == EDITPROFILE_MODAL.EMAIL) {
            this.editForm.patchValue({
                email: this.employeeDetails.email,
            })
            this.emailFeildShow = true;
        }
        else if (this.editDataValue ==  EDITPROFILE_MODAL.MOBILE) {
            this.phoneFeildShow = true;
            this.editForm.patchValue({
                countryCodeSelected: {
                    name:PHONECOUNTRY.COUNTRY,
                    iso2:PHONECOUNTRY.SHORTCODE,
                    dialCode: this.countryCode
                },
                mobile: this.employeeDetails.mobile
            });
            this.CountryCodeChange()
            if (this.editDataValue ==  EDITPROFILE_MODAL.MOBILE) {
                // this.editForm.get('email').setValidators(null);
                this.editForm.setValidators([this.utilities.validatePhoneNumber, Validators.required]);
            } else {
                this.editForm.setValidators(null);
            }
        }
    }

    formValid() {
        this.editForm = new FormGroup({
            countryCodeSelected: new FormControl({
                name: null,
                iso2: null,
                dialCode: null
            },
                []
            ),
            email: new FormControl(null, [Validators.pattern(REGEX.email)]),
            mobile: new FormControl(null)
        });
        this.editForm.statusChanges.subscribe(res => {
            ////console.log(res)
        });
    }

    countryCodeFun() {
        // this.countries = country;
        this.countries = this.appState.getAppData().appInitData.countrysCode;
    }

    CountryCodeChange() {
        this.SelectCode = this.editForm.value.countryCodeSelected.dialCode
    }

    get countryCodeSelected(): any {
        return this.editForm.get(EDIT_EMAIL_MOBILE_MODAL.COUNTRY_CODE_SELECTED);
    }
    get email(): any {
        return this.editForm.get(EDIT_EMAIL_MOBILE_MODAL.EMAIL);
    }
    get mobile(): any {
        return this.editForm.get(EDIT_EMAIL_MOBILE_MODAL.MOBILE);
    }

    closeModal() {
        this.modalCtrl.dismiss({
        });
    }

    updateEmplValueCheck(value) {
        this.updateEditProfileEnable = value
    }


    // Update employee details 
    updatEmployeeApi() {
        if (this.editForm.value.email == null) {
            this.editForm.value.email = JAVASCRIPT.BLANK
        }
        this.commonService.presentLoader();
        this.profileService.updateProfile(this.editstoreData.userDetails.employeeId, this.editForm.value.email, this.editForm.value.mobile, this.SelectCode).subscribe(res => {
            if (res) {
                this.commonService.dismissLoader();
                this.modalCtrl.dismiss({
                    'updateEditProfile': this.editUpdateProfileValue
                })
            }
            else {
                this.commonService.dismissLoader();
            }
        }, err => {
            this.commonService.dismissLoader();
            this.commonService.errorHandler(err);
        })
    }
}
