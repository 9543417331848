import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { environment } from '../../../../environments/environment'
import { StorageService } from '../storage/storage.service';
import { storageKeys } from 'src/app/app.constants';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';


@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private static DEVICE_LANGUAGE: string = 'en';
  language: string = 'en';
  languageArr = [];

  constructor(private translate: TranslateService,
    public storage: StorageService,
    private globalization: Globalization,
    private platform: Platform) {
    this.languageArr = environment.languageArr;
    //console.log("environment language array");
    //console.log(this.languageArr);
  }

  private setAppLanguageToDevice(language: String) {
    if (!this.platform.is('cordova')) {
      //console.log('device language does not get');
    } else {
      this.globalization.getPreferredLanguage().then(res => {
        // Run other functions after getting device default lang
        //console.log(res.value);
        const lang = res.value.split('-', 1);
        // LocalizationService.DEVICE_LANGUAGE = lang[0] || 'en';
        let languageCheck = this.languageArr.includes(lang[0]);
        languageCheck ? (LocalizationService.DEVICE_LANGUAGE = lang[0], this.translate.setDefaultLang(lang[0])) : (LocalizationService.DEVICE_LANGUAGE = 'en', this.translate.setDefaultLang('en'));
      }).catch(e => {
        //console.log(e)
      });
    }
  }

  /**
   * Sets the device language of the device
   */
  public async setDefaultAppLanguage() {

    let preferredLanguage = JSON.parse(await this.storage.getData(storageKeys.userPreferredLanguage))
    //console.log('preferredLanguage', preferredLanguage)
    if (preferredLanguage) {
      this.language = preferredLanguage;
      // this.language = 'en';
      LocalizationService.DEVICE_LANGUAGE = this.language;
      this.translate.setDefaultLang(this.language);
      // //console.log('preferredLanguage this.language', this.language)
    } else {
      this.language = this.translate.getBrowserLang();
      // this.language = 'hi';
      LocalizationService.DEVICE_LANGUAGE = this.language || 'en';
      this.translate.setDefaultLang(this.language);
      // this.translate.setDefaultLang('hi');
      this.setAppLanguageToDevice(this.language);
    }

  }

  public getDeviceLanguage(): String {
    return LocalizationService.DEVICE_LANGUAGE || "en";
    // return "hi";
  }
}
