import { Injectable } from '@angular/core';
import {  CanActivate, Router } from '@angular/router';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { AuthServService } from '../services/auth-serv.service';
import { StorageService } from '../services/core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(
    private router:Router,private storage:StorageService,public auth:AuthServService,  private splashScreen: SplashScreen,
  ) {
  
  }

  canActivate() {
      console.log("hitt can activate")
      if(this.auth.IsloggedIn()) {
        console.log("IsloggedIn ")
        this.router.navigateByUrl('dashboard')
        
        return true
      } 
      else {
        console.log("not IsloggedIn")
        this.router.navigateByUrl('welcome-page')
        return false
        
      }
      
    
  }
  
}
