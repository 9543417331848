import { Component, OnInit, Input } from '@angular/core';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { ModalController, NavParams } from '@ionic/angular';
import { USER_TYPE_MODAL } from 'src/app/app.constants';


@Component({
  selector: 'app-usertype',
  templateUrl: './usertype.component.html',
  styleUrls: ['./usertype.component.scss'],
})
export class UsertypeComponent implements OnInit {
  @Input() dataArr: string;
  userTypeoption: any;
  userDataStore: any;
  change: any;

  constructor(public appState: AppStateService, private modalCtrl: ModalController, navParams: NavParams, ) {
    var getArr = navParams.get(USER_TYPE_MODAL.GET_TYPE_PARAMS);
    if (getArr) {
      if (getArr.selectedUserType) {
        this.userDataStore = getArr.selectedUserType.data.userTypeData;
        this.change = getArr.userSelect;
      }else {
        this.userDataStore = getArr.selectedType;
        this.change = getArr.userSelect;
      }
    }
  }

  ngOnInit() {
    var userTypeoption = this.appState.getAppData();
    this.userTypeoption = userTypeoption.appInitData.memberType
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onItemChange(val) {
    this.change = val;
  }

  applyFilterRedemption() {
    this.modalCtrl.dismiss({
      "userTypeData": this.userDataStore,
      "userValue": this.change,
    });
  }

}
