import { Injectable } from '@angular/core';
import { NavController, Platform, AlertController } from '@ionic/angular';
import { CommonService } from './common.service';
import { AppStateService } from './init/app-state.service';
import { DashboardhomeService } from './dashboardhome.service';
import { LOCATIONSERVICE, COUNT, JAVASCRIPT, COUNT_NUMBER, LOGIN_PAGE, SELECT_OUTLET_PAGE } from 'src/app/app.constants';
import { StorageService } from './core/storage/storage.service';
import { storageKeys } from 'src/app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class LocationCheckService {
  userlatitude: any;
  userlongitude: any;
  dataLoginRes: any;
  distance: any;
  isLoginIn: any;
  loginDistance: any;

  constructor(public commonService: CommonService,
    private diagnostic: Diagnostic,
    public geolocation: Geolocation,
    public dashboardService: DashboardhomeService,
    private navCtrl: NavController,
    public platform: Platform,
    public alertCtrl: AlertController,
    public appState: AppStateService,
    public storage: StorageService,
    private translate: TranslateService, ) { }


  getUserLocation(dataLoginRes) {
    // //console.log("LOGIN DATA++>LOCATION***************" + JSON.stringify(dataLoginRes))
    this.userlatitude = dataLoginRes.latitude;
    this.userlongitude = dataLoginRes.longitude;
    this.loginDistance = dataLoginRes.loginDistance;
    this.diagnostic.isLocationEnabled().then((state) => {
      if (state == false) {
        this.commonService.dismissLoader();
        this.errorHandlerForGPSLocation();
      } else {
        // Check SSO Login Location
        if (!dataLoginRes.outletID) {
          return this.getUserLocationDirstTime(dataLoginRes);
        }
        this.commonService.presentLoader();
        this.geolocation.getCurrentPosition().then((resp) => {
          const origin = {
            lat: resp.coords.latitude,
            lng: resp.coords.longitude
          };
          let destination = {
            lat: this.userlatitude,
            lng: this.userlongitude
          };
          this.distance = this.calculateDistance(origin, destination);
          if (dataLoginRes.outletID) {
            this.callCheckRoot(dataLoginRes);
          }
        }).catch((error) => {
          if (error.code == COUNT_NUMBER.COUNT_1) {
            this.commonService.dismissLoader();
            this.errorHandlerForAppSetting();
          }
        });
      }
    }).catch(e => {
      // console.error(e)
    });
  }

  callCheckRoot(dataLoginRes) {
    this.commonService.dismissLoader();
    if (dataLoginRes.totalOutlets == parseInt(COUNT.COUNT_0) && this.distance < this.loginDistance) { //LOCATIONSERVICE.FIXEDDISTENCE
      localStorage.setItem(LOGIN_PAGE.loggedUser, 'true')
      this.navCtrl.navigateRoot('dashboard');
      this.isLoginIn = JAVASCRIPT.TRUE
      this.appState.saveLoginCheck(this.isLoginIn)

    } else if (dataLoginRes.totalOutlets > parseInt(COUNT.COUNT_1) && this.distance < this.loginDistance) { //LOCATIONSERVICE.FIXEDDISTENCE
      this.isLoginIn = JAVASCRIPT.TRUE
      this.appState.saveLoginCheck(this.isLoginIn)
      localStorage.setItem(LOGIN_PAGE.loggedUser, 'true')
      this.navCtrl.navigateRoot('select-outlet');
    } else {
      this.errorHandlerForOutletLocation();
    }
  }

  getUserLocationDirstTime(dataLoginRes) {
    let watch = this.geolocation.watchPosition();
    watch.subscribe((data:any) => {
      if (data.coords.latitude) {
        this.geolocation.watchPosition();
      }
      let origin = {
        lat: data.coords.latitude,
        lng: data.coords.longitude
      };
      let destination = {
        lat: dataLoginRes.userLatitude,
        lng: dataLoginRes.userLongitude
      };
      this.distance = this.calculateDistance(origin, destination);
      if (this.distance > this.loginDistance) {   //LOCATIONSERVICE.FIXEDDISTENCE
        this.errorHandlerForOutletLocation();
      }
    })
  }


  getUserLocationForMultiHotel(userAccess, userPageAccess) {
    console.log("inside 3", userAccess,userPageAccess )

    this.diagnostic.isLocationEnabled().then((state) => {
      console.log("inside 4",state)
      if (state == false) {
        this.commonService.dismissLoader();
        this.errorHandlerForGPSLocation();
      } else {
        console.log("inside 5",)
        this.commonService.presentLoader();
        this.geolocation.getCurrentPosition().then((resp) => {
          console.log("inside 6",resp)
          const origin = {
            lat: resp.coords.latitude,
            lng: resp.coords.longitude
          };
          let destination = {
            lat: userAccess.latitude,
            lng: userAccess.longitude
          };
          this.distance = this.calculateDistance(origin, destination);
          this.commonService.dismissLoader();
          if (this.distance > userAccess.loginDistance) {   //LOCATIONSERVICE.FIXEDDISTENCE
            this.errorHandlerFoHOTELCHANGEMSG();
          } else if (this.distance <= userAccess.loginDistance) {//LOCATIONSERVICE.FIXEDDISTENCE
            this.appState.saveUserDetailsPageAccess({
              userPageAccess: userPageAccess.userPageAccess,
              userLatitude: userAccess.latitude,
              userLongitude: userAccess.longitude,
              userLoginDistance: userAccess.loginDistance,
              userPageMbowAccess: userPageAccess.userPageMbowAccess,
            })
            localStorage.setItem(LOGIN_PAGE.loggedUser, SELECT_OUTLET_PAGE.true)
            this.storage.storageremoveItem(storageKeys.selecOutlet);
            this.isLoginIn = LOGIN_PAGE.true
            this.appState.saveLoginCheck(this.isLoginIn)
            localStorage.setItem(LOGIN_PAGE.loggedUser, 'true')
            this.dashboardService.dashboardskelton = true
            return this.navCtrl.navigateRoot('dashboard')
            
          }
        }).catch((error) => {
          console.error("error", error)
          if (error.code == COUNT_NUMBER.COUNT_1) {
            this.commonService.dismissLoader();
            this.errorHandlerForAppSetting();
          }
        });
      }
    }).catch(e => {
      console.error("err", e)
    });
  }


  // calculate the distances from point1 to point2
  calculateDistance(point1, point2) {
    const p1 = new google.maps.LatLng(
      point1.lat,
      point1.lng
    );
    const p2 = new google.maps.LatLng(
      point2.lat,
      point2.lng
    );
    return (
      google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000
    ).toFixed(1);
  }

  onLocationAppPermission() {
    this.commonService.dismissLoader();
    this.checkLocationPermission();
  }

  onLocationFormMobile() {
    this.commonService.dismissLoader();
    if (this.platform.is('ios')) {
      this.diagnostic.switchToSettings();
    } else {
      this.diagnostic.switchToLocationSettings();
    }
  }

  checkLocationPermission() {
    this.commonService.dismissLoader();
    this.diagnostic.isLocationEnabled().then((state) => {
      if (state == false) {
        this.diagnostic.switchToLocationSettings()
      } else {
        this.diagnostic.isLocationAvailable()
          .then((state) => {
            if (state == JAVASCRIPT.FALSE || state == false) {
              // do something
              this.diagnostic.switchToSettings().then((Switch) => {
              })
            } else {
              // do something else
            }
          }).catch(e => {
            console.error(e)
          });
      }

    }).catch(e => {
      console.error(e)
    });

  }

  async errorHandlerForGPSLocation() {
    const errorAlert = await this.alertCtrl.create({
      mode: 'ios',
      backdropDismiss: false,
      message: this.checkLanguage(LOCATIONSERVICE.GPSMSG),
      buttons: [{
        text: "OK",
        handler: () => {
          this.onLocationFormMobile();
        }
      }],
      cssClass: "alertCustomCss"
    });
    await errorAlert.present();
  }


  async errorHandlerForAppSetting() {
    const errorAlert = await this.alertCtrl.create({
      mode: 'ios',
      backdropDismiss: false,
      message: this.checkLanguage(LOCATIONSERVICE.APPMSG),
      buttons: [{
        text: "OK",
        handler: () => {
          this.checkLocationPermission();
        }
      }],
      cssClass: "alertCustomCss"
    });
    await errorAlert.present();
  }

  async errorHandlerFoHOTELCHANGEMSG() {
    const errorAlert = await this.alertCtrl.create({
      mode: 'ios',
      backdropDismiss: false,
      message: this.checkLanguage(LOCATIONSERVICE.HOTELCHANGEMSG),

      buttons: [{
        text: "OK"
      }],
      cssClass: "alertCustomCss"
    });
    await errorAlert.present();
  }


  async errorHandlerForOutletLocation() {
    const errorAlert = await this.alertCtrl.create({
      mode: 'ios',
      backdropDismiss: false,
      message: this.checkLanguage(LOCATIONSERVICE.OUTLETMSG),

      buttons: [{
        text: "OK",
        handler: () => {
          this.storage.remove(storageKeys.loginCheck)
          this.storage.remove(storageKeys.userDetails)
          this.storage.remove(storageKeys.sessionToken)
          this.storage.remove(storageKeys.userPageAccess)
          this.storage.remove(storageKeys.appInitData)
          this.storage.remove(storageKeys.userDetailsForChat)
          this.storage.remove(storageKeys.employeeidHeader)
          this.storage.remove(storageKeys.devicCheckBase64)
          this.commonService.sessionExpireEnable = false
          localStorage.removeItem(LOGIN_PAGE.loggedUser)
          this.commonService.dashboardApi = false
          this.storage.remove(storageKeys.TestLogin)
          this.appState.saveSessionToken(JAVASCRIPT.BLANK);
          this.appState.saveUserDetails(JAVASCRIPT.BLANK);
          this.appState.saveEmployeIdforHeader(JAVASCRIPT.BLANK);
          this.appState.saveLoginCheck(JAVASCRIPT.BLANK);
          this.storage.storageClear();
          this.storage.storageremoveItem(storageKeys.userPreferredLanguage);
          navigator['app'].exitApp();
        }
      }],
      cssClass: "alertCustomCss"
    });
    await errorAlert.present();
  }


  checkLanguage(val) {
    let msg = JAVASCRIPT.BLANK;
    this.translate.get(val).subscribe((res) => {
      msg = res;
    })
    return msg;
  }
}
