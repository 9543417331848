import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { AppStateService } from './init/app-state.service';
import { InitService } from './init/init.service';
import { Platform } from '@ionic/angular';
import { StorageService } from './core/storage/storage.service';
import { DEVICECHECK_SERVICES } from '../app.constants';
declare var window: any;

@Injectable({
    providedIn: 'root'
})

export class AndroidIosDevideCheckSafetynetService {
    nonce: any;
    loadingDialog: any;

    constructor(public common: CommonService,
        public appState: AppStateService,
        private initService: InitService,
        public platform: Platform,
        public storage: StorageService) {
    }

    tokenCreation(): Promise<any> {
        return new Promise<any>((resolve) => {
            if (this.platform.is("android")) {
                console.log("sucusstoken",this.initService.environment.GOOGLE_API_KEY)
                this.nonce = this.randomString(16)
                window.safetynet.attest(this.nonce, this.initService.environment.GOOGLE_API_KEY, (sucuss: any) => {
                    console.log("sucusstoken",sucuss)
                    this.saveDeviceCheckdata(sucuss);
                    resolve(DEVICECHECK_SERVICES.sucuss);
                }, (error: any) => {
                    console.log("error",error)
                    resolve(DEVICECHECK_SERVICES.error)
                });
            } else {
                let SalesforceSnapIns = window.cordova.plugins.SalesforceSnapIns;
                let val = this;
                SalesforceSnapIns.deviceCheck(function (data) {
                    val.saveDeviceCheckdata(data);
                    resolve(DEVICECHECK_SERVICES.sucuss);
                }, function (err) {
                    console.error(err);
                });
            }
        });
    }



    randomString(length) {
        var text = DEVICECHECK_SERVICES.BLANK;
        var possible = DEVICECHECK_SERVICES.possible;
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }


    saveDeviceCheckdata(data) {
        this.appState.saveDeviceCheckBase64({
            saveDevicecheckBase64: data,
            currTimeStamp: new Date().getTime()
        });
    }

}
