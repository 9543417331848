import { MenuAllComponent } from './../modals/menu-all/menu-all.component';
import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from '@angular/core';
import { BackButtonComponent } from './back-button/back-button.component';
import { QueryComponent } from './query/query.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackStarComponent } from './feedback-star/feedback-star.component';
import { ReservationDetailsComponent } from '../modals/reservation-details/reservation-details.component';
import { ReservationRegretComponent } from '../modals/reservation-regret/reservation-regret.component';
import { HandleWithCareComponent } from '../modals/handle-with-care/handle-with-care.component';
import { RecordExceptionCategoryComponent } from '../modals/record-exception-category/record-exception-category.component';
import { SetDateComponent } from '../modals/set-date/set-date.component';
import { RespondmodelComponent } from '../modals/respondmodel/respondmodel/respondmodel.component';
import { EditEmailMobileComponent } from '../modals/edit-email-mobile/edit-email-mobile.component';
import { BenefitsComponent } from '../modals/benefits/benefits.component';
import { OtpComponent } from './otp/otp.component';
import { TranslateModule } from '@ngx-translate/core';
import { UsertypeComponent } from '../modals/usertype/usertype.component';
import { WalkInComponent } from '../modals/walk-in/walk-in.component';
import { QrScannerComponent } from '../modals/qr-scanner/qr-scanner.component';
import { AllowOtpComponent } from '../modals/allow-otp/allow-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { PdfViewersComponent } from '../modals/pdf-viewers/pdf-viewers.component';
import { SelectMembershipComponent } from '../modals/select-membership/select-membership.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TimeSlotWrapWindowComponent } from './time-slot-wrap-window/time-slot-wrap-window.component';
import { MainPipe } from '../pipes/main-pipe.module';
import { ErrorMessageComponent } from './error-message/error-message.component';

@NgModule({
    declarations: [
        BackButtonComponent,
        QueryComponent,
        FeedbackStarComponent,
        ReservationDetailsComponent,
        ReservationRegretComponent,
        HandleWithCareComponent,
        RecordExceptionCategoryComponent,
        SetDateComponent,
        RespondmodelComponent,
        EditEmailMobileComponent,
        BenefitsComponent,
        OtpComponent,
        UsertypeComponent,
        WalkInComponent,
        QrScannerComponent,
        AllowOtpComponent,
        PdfViewersComponent,
        SelectMembershipComponent,
        TimeSlotWrapWindowComponent,
        ErrorMessageComponent,
        MenuAllComponent
    ],
    imports: [
        MbscModule,
        NgOtpInputModule,
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        PdfViewerModule,
        MainPipe
    ],
    exports: [
        BackButtonComponent,
        QueryComponent,
        FeedbackStarComponent,
        OtpComponent,
        TimeSlotWrapWindowComponent,
        ErrorMessageComponent
    ],
    providers: [],
    entryComponents: [
        ReservationDetailsComponent,
        ReservationRegretComponent,
        HandleWithCareComponent,
        RecordExceptionCategoryComponent,
        SetDateComponent,
        RespondmodelComponent,
        EditEmailMobileComponent,
        BenefitsComponent,
        UsertypeComponent,
        WalkInComponent,
        QrScannerComponent,
        AllowOtpComponent,
        PdfViewersComponent,
        SelectMembershipComponent,
        TimeSlotWrapWindowComponent,
        ErrorMessageComponent,
        MenuAllComponent
    ]
})
export class ComponentsModule { }