import { Injectable } from '@angular/core';
import * as phoneValidation from "libphonenumber-js";
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { JAVASCRIPT } from '../app.constants';
import * as moment from 'moment';
import { TimestampConvertPipe } from '../pipes/timestamp-convert.pipe';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  validatePhoneNumber(control: FormControl) {
    let isValidNumber;
    let stringNumber = control.value.mobile ? control.value.mobile.toString() : JAVASCRIPT.BLANKDOUBLE;
    let upperCaseCountryCode = control.value.countryCodeSelected ? control.value.countryCodeSelected.iso2.toUpperCase() : JAVASCRIPT.BLANKDOUBLE;
    let parsedNumber: any = phoneValidation.parseNumber(stringNumber, upperCaseCountryCode);
    isValidNumber = phoneValidation.isValidNumber(parsedNumber);
    if (isValidNumber){
      return null;
    }
    return {isInvalidNumber: true};
  }

  timeGreaterThan() {
    console.log("hitt 1=>> ",)
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      console.log("control.parent ",control.parent)
      if (!control.parent) {
        console.log("hitt 2 =>> ")
          return null;
      }
      let controlValue = control.value[0];
      let startTime = moment(controlValue.startTimeHour+":"+controlValue.startTimeMinute+" "+controlValue.startTimePeriod, 'hh:mm A');
      let endTime = moment(controlValue.endTimeHour+":"+controlValue.endTimeMinute+" "+controlValue.endTimePeriod, 'hh:mm A');
      console.log("controlValue =>> ",controlValue)
      console.log("startTime =>> ",startTime)
      console.log("endTime =>> ",endTime)

      if (endTime.isAfter(startTime)) {
        console.log("hitt 3=>> ")
        return null;
      }
      return {
        
        'timeGreaterThan': true
      }
    };
  }

  checkTimeGreater() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
          return null;
      }
      let controlValue = control.value;
      let date = new Date(controlValue.startDate);
      let startDate = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
      date = new Date(controlValue.endDate);
      let endDate = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
      let startTime = startDate + " " +controlValue.startTimeHour+":"+controlValue.startTimeMinute+" "+controlValue.startTimePeriod;
      let endTime = endDate + " " +controlValue.endTimeHour+":"+controlValue.endTimeMinute+" "+controlValue.endTimePeriod;
      if (new Date(startTime).getTime()/1000 < new Date(endTime).getTime()/1000) {
        return null;
      }
      return {
        'checkTimeGreater': true
      }
    };
  }

  checkEndTime() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
          return null;
      }
      const result = control.value.forEach(element => {
        let startTime = moment(new TimestampConvertPipe().transform(element, 'start'));
        let endTime = moment(new TimestampConvertPipe().transform(element, 'end'));
        if (startTime.isSameOrAfter(endTime)) {
          return true;
        }
        return false;
      });
      if (result) {
        return {checkEndTime: true}
      }
      return null;
    };
  }

  isSameOrAfter() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
          return null;
      }
      let value = control.parent.value;
      if (value.startDate && control.value) {
        let startDate = moment(value.startDate).format("YYYY-MM-DD");
        let endDate = moment(control.value).format("YYYY-MM-DD");
        if (moment(endDate).isSameOrAfter(startDate)) {
          return null;
        } else {
          return {
            'isSameOrAfter': true
          }
        }
      }
    };
  }

  isTimeAfter() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
          return null;
      }
      let value = control.parent.value;
      if (value.startDate && value.endDate) {
        let startDate = moment(moment(value.startDate).format("YYYY-MM-DD") + " " +
            value.startTimeHour + ":" + 
            value.startTimeMinute + " " + 
            value.startTimePeriod)
            .format("YYYY-MM-DD hh:mm A");
        let endDate = moment(moment(value.endDate).format("YYYY-MM-DD") +
             " " + value.endTimeHour + ":" + value.endTimeMinute + " " + value.endTimePeriod)
             .format("YYYY-MM-DD hh:mm A");
        console.log("Dates::>", new Date(startDate).getTime()/1000 < new Date(endDate).getTime()/1000);
        if (Number(new Date(startDate).getTime())/1000 < Number(new Date(endDate).getTime())/1000) {
          return null;
        } else {
          return {
            'isTimeAfter': true
          }
        }
      }
    }
  }
  
  endDateGreater() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
          return null;
      }
      let date: any;
      let startDate: any;
      let endDate: any;
      if (control.parent.value.startDate) {
        date = new Date(control.parent.value.startDate);
        startDate = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
      }
      if (control.value) {
        date = new Date(control.value);
        endDate = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
      }
      if (startDate && endDate) {
        if (new Date(startDate).getTime()/1000 <= new Date(endDate).getTime()/1000) {
          return null;
        }
      }
      return {
        'endDateGreater': true
      }
    };
  }

  todayOrNext() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
          return null;
      }
      if (moment(moment(control.value).format("YYYY-MM-DD")).isSameOrAfter(moment(new Date).format('YYYY-MM-DD'))) {
        return null;
      }
      return {
        'todayOrNext': true
      }
    };
  }

  isCategoryDuplicate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formArray = control.value;
      const data = formArray.map((item: any) => item.categoryId);
      const categoryId = data.filter((item: any) => item);
      const result = categoryId.some((element: string) => {
        if (categoryId.indexOf(element) !== categoryId.lastIndexOf(element)) {
          return true;
        }
        return false;
      });
      if (result) {
        return { isCategoryDuplicate: true };
      }
      return null;
    }
  }

  isNumeric(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (!(/^\d+$/.test(value))) {
        return { isNumeric: true };
      }
      return null;
    }
  }

  isNumericFloat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (!(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value))) {
        return { isNumericFloat: true };
      }
      return null;
    }
  }

  onlyDigitsFloat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (!(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value)) && value) {
        control.setValue(value.slice(0, -1));
      }
      return null;
    }
  }

  onlyDigits(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (!(/^\d+$/.test(value)) && value) {
        control.setValue(value.slice(0, -1));
      }
      return null;
    }
  }

  greaterThan(fieldName: string, compareValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (parseInt(value) <= compareValue) {
        return { greaterThan: true };
      }
      return null;
    }
  }

  maxLength(fieldName: string, compareValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (value) {
        if (value.length > compareValue) {
          control.setValue(value.substr(0, compareValue));
        }
      }
      return null;
    }
  }

  mustBeUnique(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      const items = value.map((item: any) => item.ItemPOSCode);
      const posCodes = items.filter((item: any) => item);
      const result = posCodes.some((element: string) => {
        if (posCodes.indexOf(element) !== posCodes.lastIndexOf(element)) {
          return true;
        }
        return false;
      });
      if (result) {
        return { mustBeUnique: true };
      }
      return null;
    }
  }

  /**
   * Mobile number validation in which countryCodeFiled from the form field to be pass to attach with number
   * while control is mobile number field itself
   */
   mobileNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let stringNumber = control.value ? control.value.toString() : "";
      let countryCode = control.parent.value.countryCodeSelected;
      let parsedNumber: any = phoneValidation.parseNumber("+" + countryCode + stringNumber);
      let isValid = phoneValidation.isValidNumber(parsedNumber);
      if (!isValid) {
        return { mobileNumberValidator: { isValid } };
      } else {
        return null;
      }
    };
  }
}
