import { Injectable } from '@angular/core';
import { NativeGeocoder,NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Geolocation,Coordinates} from '@awesome-cordova-plugins/geolocation/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
/**
 * Location Service class for location related operations.
 */
export class LocationService {

  private static DEVICE_COUNTRY: String;

  constructor(private geolocation: Geolocation, private nativeGeocoder: NativeGeocoder,
    public plt: Platform,
  ) { }

  /**
   * Gets the current location of the device.
   * Call using await.
   * @returns position - Geoposition: position of device. Use position.coords.latitude, position.coord.longitude
   */

  public async getCurrentLocation() {
    if (this.plt.is('desktop') == false && this.plt.is('mobileweb') == false) {
      return await this.geolocation.getCurrentPosition();
    }
  }

  /**
   * Gets the current country of the device. 
   * Call using await.
   */
  public getCurrentCountry() {
    // ////console.log('Bool---->',this.plt.is('desktop') == false && this.plt.is('mobileweb') == false);
    if (this.plt.is('desktop') == false && this.plt.is('mobileweb') == false) {
      if (!LocationService.DEVICE_COUNTRY) {
        this.setCurrentCountry();
      }
      return LocationService.DEVICE_COUNTRY;
    }


  }

  public async setCurrentCountry() {
    var countryCode: String = LocationService.DEVICE_COUNTRY;

    if (!countryCode) {
      var location = await this.getCurrentLocation();
      if (location && location.coords) {
        var reverseLocation = await this.reverseGeocode(location.coords);
        if (reverseLocation && reverseLocation.length > 0) {
          countryCode = reverseLocation[0].countryCode;
          LocationService.DEVICE_COUNTRY = countryCode;
        }
      }
    }
  }

  /**
   * Reverse geocode a given latitude and longitude to find location address
   * @param coordinates The coordinates
   */
  private async reverseGeocode(coordinates: Coordinates) {
    if (this.plt.is('desktop') == false && this.plt.is('mobileweb') == false) {
      let options: NativeGeocoderOptions = {
        useLocale: true,
        maxResults: 1
      };
      return await this.nativeGeocoder.reverseGeocode(coordinates.latitude, coordinates.longitude, options);
    }
  }
}
