import { Injectable } from '@angular/core';
import { AppStateService } from '../../init/app-state.service';
import { StorageService } from '../storage/storage.service';
import { CommonService } from '../../common.service';

@Injectable({
  providedIn: 'root'
})
export class HTTPErrorHandler {
  isLogined: any;
  constructor(public appState: AppStateService, public commonService: CommonService,
    public storage: StorageService) { }

  public handleError(err: any) {

  }
}
