import { Injectable } from '@angular/core';
//import { FCM } from '@ionic-native/fcm/ngx';
import { ModalController, NavController, Platform } from '@ionic/angular';

import { CommonService } from 'src/app/services/common.service';
import { Push, PushObject, PushOptions } from '@awesome-cordova-plugins/push/ngx';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";

const options: PushOptions = {
  android: {
    senderID: '375015156198',
    forceShow: true,
    sound:'testing.mp3',
    icon: 'fcm_push_icon'
  },
  ios: {
    alert: true,
    badge: false,
    sound: true,
    categories: {
      shareAction: {
        yes: {
          callback: 'share',
          title: 'Share',
          foreground: true,
          destructive: false
        }
      }
    }
  },
  windows: {}
};

@Injectable({
  providedIn: 'root'
})

export class PushNotificationService {
  visitorGetCloudId: any;
  platformName: any;
  memberid: any;
  membershipNumber: any;
  constructor(
    private push: Push,
    private platform: Platform,
    public commonServ: CommonService,
    public appState: AppStateService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    public translate: TranslateService,
    private router: Router) {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.push.hasPermission()
          .then((res: any) => {

            if (res.isEnabled) {
          
              console.log('We have permission to send push notifications');
            } else {
              console.log('We do not have permission to send push notifications');
            }

          });
      }
    });
  }

 

  generatePushNotificationToken() {
  
    if (!this.platform.is('cordova')) {
      console.log('couldnot generate token');
    } else {


      this.push.createChannel({
        id: "testchannel1",
        description: "My first test channel",
        // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
        importance: 4,
        //badge is used to if badge appears on the app icon see https://developer.android.com/reference/android/app/NotificationChannel.html#setShowBadge(boolean).
        //false = no badge on app icon.
        //true = badge on app icon
        badge: true,
        sound:'testing.mp3',
       }).then(() => console.log('Channel created'));


      console.log("Pushnotification - is that calling??");
      console.log("options", options);
      const pushObject: PushObject = this.push.init(options);
      pushObject.on('registration').subscribe((data: any) => {
        console.log('device token push notification ----->>>>', JSON.stringify(data), "_____", options);
      }, err => {
        console.log("Pushnotification - Registration error -->>", err);
      });

      pushObject.on('share').subscribe((data: any) => {
        console.log('notification calback response ------>>>>' + JSON.stringify(data)); /// we are getting notification actions response here
        this.actionNotifi(data)
      });

      pushObject.on('notification').subscribe((data: any) => {
        console.log('notification response ------>>>>' + JSON.stringify(data)); /// we are getting notification response here
        console.log('message -> ' + data.message);
        this.actionNotifi(data)
      });

      pushObject.on('error').subscribe(error => console.error('Error with Push plugin' + error));
    }
  }





  actionNotifi(data) {
    console.log("data res", data)
    var userLogging = localStorage.getItem('loggedUser')
    if (userLogging == 'true') {
      this.navCtrl.navigateRoot('dashboard');
    }
  }
}


