import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { PDFVIEWERS_MODAL } from 'src/app/app.constants';

const ZOOM_STEP: number = 0.25;
const DEFAULT_ZOOM: number = 1;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewers.component.html',
  styleUrls: ['./pdf-viewers.component.scss'],
})
export class PdfViewersComponent implements OnInit {
  @Input() pdfURL: string;
  showPDFUrl: any;
  public pdfZoom: number = DEFAULT_ZOOM;
  showHeading: any;

  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
  ) {
    let data = navParams.get(PDFVIEWERS_MODAL.pdfURL);
    console.log("pdf",data)
    this.showHeading = data.pdfHeading
    this.showPDFUrl = data.getPdfURL;
  }

  ngOnInit() { }

  closeModal() {
    this.modalController.dismiss()
  }
  public zoomIn() {
    this.pdfZoom += ZOOM_STEP;
  }

  public zoomOut() {
    if (this.pdfZoom > DEFAULT_ZOOM) {
      this.pdfZoom -= ZOOM_STEP;
    }
  }

  public resetZoom() {
    this.pdfZoom = DEFAULT_ZOOM;
  }

}
