import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpWrapperService, HttpInputData } from './core/http/http-wrapper.service';
import { appApiResources } from './../app.constants';
import { CommonService } from './common.service';
import { AppStateService } from './init/app-state.service';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userRecord: any = []
  certificateDetails: any;
  memberResponseData: any;
  cardBenfitDetails: any;
  mobileNo: number;
  email: any;
  membershipNumber: any;
  customerPersonalDetails: any;
  currentDate = new Date().toISOString().split('T')[0];
  manageStoreData: any;
  dataHandle: any = [];
  dataForReedemBenifit: any
  redeemScreenCheck: boolean;
  listData: any;
  listDetails: any;
  infoData: any;
  checkCertificateScreen: string;
  poolGuest: any;
  outletApiResponce

  constructor(
    public http: HttpClient,
    public httpWrapper: HttpWrapperService,
    public commonService: CommonService,
    public appState: AppStateService,
    public plt: Platform
  ) {
    this.manageStoreData = this.appState.getAppData();
  }

  employeProfile(employeeId) {
    return this.httpWrapper.get(`${appApiResources.accountUrl}?accountId=${employeeId}`)
      .pipe(map((response: any) => {
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}

  updateProfile(employeeId, email, mobileNo, countryCode) {
    let dataUpdate = {
      "email": email,
      "contactNumber": {
        "countryCode": countryCode,
        "number": countryCode + '' + mobileNo
      }
    }
    let options = new HttpInputData();
    return this.httpWrapper.put(`${appApiResources.accountUrl}?accountId=${employeeId}`, dataUpdate, options)
      .pipe(map((response: any) => {
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}

  // recrodApi(start, endDate, serachRecord) {
  //   return this.httpWrapper.get(`${appApiResources.IPReservationsUrl}?&startDate=${start}&endDate=${endDate}&searchText=${serachRecord} `)
  //     .map((response: any) => {
  //       return response;
  //     })
  //   // .catch((error: Response) => this.commonService.errorHandler(error))
  // }


  recrodApi(start, endDate, serachRecord) {
    console.log("serachRecord1")
    if (serachRecord) {
      return this.httpWrapper.get(`${appApiResources.recrodSearchUrl}&searchText=${serachRecord}`)
        .map((response: any) => {
          console.log("serachRecord2")

          return response;
        }).catch((error: Response) => this.commonService.errorHandler(error) )
        

    } else {
      console.log("serachRecord3")
      return this.httpWrapper.get(`${appApiResources.recrodUrl}&startDate=${start}&endDate=${endDate}&searchText=${serachRecord}`)
        .map((response: any) => {
          console.log("serachRecord4")

          return response;
        }).catch((error: Response) => this.commonService.errorHandler(error)
        )
    }
    
  }
  

  updateRecordQuery(reasonForException, category, userRecord) {
    console.log(userRecord)
    let dataUpdate = {
      "membershipNumber": userRecord.membershipNumber,
      "tableNumber": userRecord.tableNo,
      "reservationID": userRecord.sfid,
      "category": category,
      "reasonForException": reasonForException

    }
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.uploadRecordExceptionUrl, dataUpdate, options)
      .pipe(map((response: any) => {
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}

  //*...memberProfile Api...*/

  getmemberProfile() {
    return this.httpWrapper.get(appApiResources.memberProfile + this.membershipNumber).pipe(map((response: any) => {
      return response;
    })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
    )}

  //*...Redemption Log  Api...*/
  redemptionAPI(redemtionData) {
    var  bucketSize
    if(this.manageStoreData.appInitData.bucketSize) {
      bucketSize = this.manageStoreData.appInitData.bucketSize 
    } else {
      bucketSize = 10
    }
    
    return this.httpWrapper.get('redemptionlogs?bucketSize='+bucketSize+'&bucketNo=' + redemtionData.bucketNo + '&searchType=' + redemtionData.searchType + '&userType=' + redemtionData.userType + '&startDate=' + redemtionData.startDate + '&endDate=' + redemtionData.endDate)
      .pipe(map((response: any) => {
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}

  mailReportAPI(redemtionMailReport) {
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.redemptionEmail, redemtionMailReport, options)
      .pipe(map((response: any) => {
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}

  //* handleWithCare api starts here */

  handleCare(sfid, outletId, propertyid, description) {
    let requestData = {
      'memberId': this.manageStoreData.userDetails.employeeId,
      'outletId': outletId,
      'PropertyId': propertyid,
      'desc': description,
      'loggedDate': this.currentDate
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(`membership/` + sfid + `/handlewithcare`, requestData, options)
      .pipe(map((response: any) => {
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}


  redeemApiConfirm(customerInfo, redeemDetails, outletId, transactionId, otp) {

    //console.log(transactionId)

    if (redeemDetails.outletType == 'Restaurant' || redeemDetails.outletType == 'Spa'
    || redeemDetails.outletType == 'Accommodation') {
      redeemDetails.guestCount

    } else if (redeemDetails.outletType == 'Pool') {
      ////console.log("pool")
      this.poolGuest = redeemDetails.adultCount + redeemDetails.childCount
    }
    let requestData = {
      "mobile": customerInfo.mobile,
      "email": customerInfo.email,
      "benefitID": redeemDetails.certificateDetails[0].sfdcData.certificateBenefitId || redeemDetails.certificateDetails[0].sfdcData.cardBenefitId,
      "outletID": outletId,
      "otp": otp,
      "guestCount": String(this.poolGuest || redeemDetails.guestCount),
      "adultCount": String(redeemDetails.adultCount),
      "kidsCount": String(redeemDetails.childCount),
      "transactionID": transactionId.transactionID || transactionId,
      "reservationId": ""
      // ,
      // "memberId":customerInfo.membershipNumber
    }
    console.log("requestData==>",requestData)
    let options = new HttpInputData();
    return this.httpWrapper.post(`redeemBenefit?membershipNumber=${customerInfo.membershipNumber}`, requestData, options)
      .pipe(map((response: any) => {
        this.redeemScreenCheck = true
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}


  allotReservartionWalkIn(getDate, GetfinalTime, tableNo, memershipNumber, outletId, outletType, checkNo, offerid) {
    var outletPass
    outletPass = outletType === 'Restaurant' ? true : false
    let data = {
      "reservationDate": getDate,
      "reservationTime": GetfinalTime + ':00',
      "outletsfid": outletId,
      "tcAccepted": true,
      "memberShipNumber": memershipNumber.membershipNumber || memershipNumber,
      "offerUniqueIdentifier": offerid || '',
      "checkNumber": checkNo,
      "tableNumber": tableNo,
      "checkTable": outletPass,
      "guestNames": "",

    }
    let options = new HttpInputData();
    return this.httpWrapper.post(appApiResources.reservationwalkinUrl, data, options)
      .pipe(map((response: any) => {
        return response;
      })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
      )}

  topspenderAiFunction(spenderselect, month, year, currentDate) {
    if (month < 10) { month = '0' + month; }
    if (spenderselect == 'MTD') {
      var startDate = year + '-' + month + '-' + '01'
    } else if (spenderselect == 'YTD') {
      var startDate = year + '-' + '01' + '-' + '01'
    }
    return this.httpWrapper.get(`${appApiResources.topspender}?limit=25&startDate=${startDate}&endDate=${currentDate}`).pipe(map((response: any) => {
      return response;
    })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
    )}

  topspenderDeatilAiFunction(memberId) {
    return this.httpWrapper.get(`members/${memberId}`).pipe(map((response: any) => {
      return response;
    })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
    )}

  getSelectMembershipImageFunc(customerSets) { // AEM API
    //console.log('getAEM Data Called', String(customerSets));
    return this.httpWrapper.postAemData(environment.aem_url_exporter, customerSets).pipe(map((res: any) => {

      if (this.plt.is('cordova')) {
        //impotanat commenet not remove when you go test aem api in mobile this section is open 
        var data = JSON.parse(res)
        return data;
      } else {
        // impotant msg when you serve in web view open this return above return data for mobile this for web view
        return res;
      }

    }, err => {
      //console.log('selectMembershipImageErr ==>', err);
    })
    )}

  dashboardGraphFunction(outsfid, ytdandmtdValue) {
    return this.httpWrapper.get(`outlet/${outsfid}/misreports?mistype=${ytdandmtdValue}`).pipe(map((response: any) => {
      return response;
    })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
    )}

  outletDeailsAPI(manageCertificateDetails) {
    return this.httpWrapper.get(`details?uniqueIdentifier=${manageCertificateDetails.benefitCode}&offerType=${manageCertificateDetails.benefitType}`).pipe(map((response: any) => {
      return response;
    })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
    )}

  outletDeailsAPIFromGuest(manageCertificateDetails) {
    //console.log("dataCardand CAERTIFICAE", manageCertificateDetails)
    var sendRequestCertificate
    var sendRequestIndetifier
    if (manageCertificateDetails[0].sfdcData.identifierType == 'certificate') {
      sendRequestCertificate = 'Certificate'
      sendRequestIndetifier = manageCertificateDetails[0].sfdcData.certificateBenefitId
    } else if (manageCertificateDetails[0].sfdcData.identifierType == 'cardbenefit') {
      sendRequestCertificate = 'Card'
      sendRequestIndetifier = manageCertificateDetails[0].sfdcData.cardBenefitId

    }

    return this.httpWrapper.get(`details?uniqueIdentifier=${sendRequestIndetifier}&offerType=${sendRequestCertificate}`).pipe(map((response: any) => {
      return response;
    })
      ,catchError((error: Response) => this.commonService.errorHandler(error))
    )}



}

