import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'timestampConvert'
})
export class TimestampConvertPipe implements PipeTransform {
  
  transform(value: any, type: string): string {
    let timeString: string;
    if (type == 'start') {
      timeString = moment(value.startTimeHour + ":" + value.startTimeMinute + " " + value.startTimePeriod, "hh:mm A").format("hh:mm A");
    } else if (type == 'end') {
      timeString = moment(value.endTimeHour + ":" + value.endTimeMinute + " " + value.endTimePeriod, "hh:mm A").format("hh:mm A");
    }
    return timeString;
  }

}
