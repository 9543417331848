import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RESPONDMODEL_MODAL } from 'src/app/app.constants';

@Component({
    selector: 'app-respondmodel',
    templateUrl: './respondmodel.component.html',
    styleUrls: ['./respondmodel.component.scss'],
})
export class RespondmodelComponent implements OnInit {
    feedBackModal: any;
    constructor(private modalCtrl: ModalController) { }

    ngOnInit() { }

    closeModal() {
        this.modalCtrl.dismiss()
    }


    onClick() {
        ////console.log("this is the feeedback Modal-=-=-=-=-", this.feedBackModal);
        this.modalCtrl.dismiss({
            "messageModal": this.feedBackModal
        })
    }

    restrictText(e) {
        if (this.feedBackModal.length == RESPONDMODEL_MODAL.COUNT_99) {
            e.preventDefault();
        } else if (this.feedBackModal.length > RESPONDMODEL_MODAL.COUNT_99) {
            // Maximum exceeded
            this.feedBackModal = this.feedBackModal.substring(RESPONDMODEL_MODAL.COUNT_0, RESPONDMODEL_MODAL.COUNT_99);
        }
    }

}
