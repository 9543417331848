import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { RECORD_EXC_PAGE } from 'src/app/app.constants';

@Component({
  selector: 'app-record-exception-category',
  templateUrl: './record-exception-category.component.html',
  styleUrls: ['./record-exception-category.component.scss'],
})
export class RecordExceptionCategoryComponent implements OnInit {

  recordData: any
  recordException: any;
  optionRecordAll: any = [];
  getDataResonException: any

  constructor(
    private modalCtrl: ModalController,
    public appState: AppStateService,
    navParams: NavParams,
  ) {
    this.getDataResonException = navParams.get(RECORD_EXC_PAGE.checkedValue)
  }

  ngOnInit() {
    if (this.getDataResonException) {
      ////console.log("hitt")
      this.recordData = this.getDataResonException
    } else {
      ////console.log("elseee")
    }
    this.recordException = this.appState.getAppData();
    this.optionRecordAll = this.recordException.appInitData.recordExceptionCategories
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  update() {
    this.modalCtrl.dismiss({
      'recordOptionData': this.recordData
    });
  }

  selectFuntion() {

  }
}
