import { Component, OnInit, Input, Output, NgZone, EventEmitter, ViewChild } from '@angular/core';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { CommonService } from 'src/app/services/common.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { Platform, ModalController } from '@ionic/angular';
import { AllowOtpComponent } from 'src/app/modals/allow-otp/allow-otp.component';
import { JAVASCRIPT, COUNT, OTP_COMPONENT, COUNT_NUMBER } from 'src/app/app.constants';
import { InitService } from 'src/app/services/init/init.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/services/event/event.service';
// import { inputs } from '@syncfusion/ej2-angular-popups/src/dialog/dialog.component';
// declare var SMSReceive: any;

@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss'],

})

export class OtpComponent implements OnInit {
    @Input() otpType: string = JAVASCRIPT.BLANK;
    @Input() resetRequest: any;
    @Input() sendDataForOtpData: any;
    @Input() redeem: any;
    @Input() hideValidationReset: any;
    @Input() hideValidation: any;
    @Input() editstoreData: any;
    @Input() sendingResendtransactionDetails: any;
    @Output() otpValidCode = new EventEmitter();
    @Output() otpMthodType = new EventEmitter();
    @Output() sendOTP = new EventEmitter();
    @Output() sendRedeemInfo = new EventEmitter();
    @Output() sendEnableRedeemButton = new EventEmitter();
    @Output() emailEditProfileUpdate = new EventEmitter();
    @Input(OTP_COMPONENT.show) show: string;
    @Input() placeholder: any;
    @Input() openReservation: boolean;
    @Output() openReservationOtpVerified = new EventEmitter();
    @ViewChild(OTP_COMPONENT.otpAutoForReset, { static: false }) otpAutoForReset: any;
    config = {
        allowNumbersOnly: true,
        length: parseInt(COUNT.COUNT_4),
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: JAVASCRIPT.BLANK,
    };
    otpOne: any;
    otpTwo: any;
    otpThree: any;
    otpFour: any;
    otpEntered: string;
    otpResData: any;
    otpAllFeild: any;
    resendEnable: boolean = false;
    otpFeildEnabled: boolean = false;
    otpStrorageSfid: any;
    sendVlaueEditprofile: boolean = false
    reedemForEnableButton: boolean;
    incomingSMS: any;
    count: number;
    countDown: any = "";
    enableResendAfterCount: boolean = false
    otpReuestTypeButton: any;
    infoIconMob: boolean;
    infoIconEmail: boolean;
    constructor(
        public service: OnboardingService,
        public common: CommonService,
        private platform: Platform,
        private initService: InitService,
        public modalCtrl: ModalController,
        public zone: NgZone,
        public appState: AppStateService,
        public translate: TranslateService,
        public event: EventService
        ) {

            event.subscribe('activatePageMob', (res) => {
                this.infoIconMob = res 
              })
              event.subscribe('activatePageEmail', (res) => {
                this.infoIconEmail = res 
              })
    }

    ngOnInit() {

        this.config.placeholder = this.placeholder;
        if (this.redeem) {
            this.service.redeemRequest = true
            console.log(this.redeem)
            this.otpSend(JAVASCRIPT.BLANK)
        }
    }

    clearFieldOtp(obj, val) {
        obj.value = null;
        switch (val) {
            case parseInt(COUNT.COUNT_1):
                this.otpOne = null;
                break;
            case parseInt(COUNT.COUNT_2):
                this.otpTwo = null;
                break;
            case parseInt(COUNT.COUNT_3):
                this.otpThree = null;
                break;
            case parseInt(COUNT.COUNT_4):
                this.otpFour = null;
                break;
        }
    }

    //** Push To OTP */ 
    pushOtp(prevobj, obj, nextobj) {
        if (obj.value) {
            if (nextobj === null) {
                this.otpFour = obj.value;
                if (this.redeem) {
                    var OTP
                    OTP = this.otpOne + JAVASCRIPT.BLANK + this.otpTwo + JAVASCRIPT.BLANK + this.otpThree + JAVASCRIPT.BLANK + this.otpFour
                    this.reedemForEnableButton = true
                    this.EnableRedeemButton(this.reedemForEnableButton, OTP)
                }
                else {
                    this.optVerifyFun(OTP)
                }
            } else {
                this.reedemForEnableButton = false
                this.EnableRedeemButton(this.reedemForEnableButton, OTP)
                nextobj.setFocus();
            }
        } else {

            prevobj.value = null
            this.reedemForEnableButton = false
            this.EnableRedeemButton(this.reedemForEnableButton, OTP)
            prevobj.setFocus();
        }
    }

    EnableRedeemButton(data, OTP) {
        this.sendEnableRedeemButton.emit(data);
        this.sendOTP.emit(OTP);
    }

    //**Send OTP FUNTION */
    otpSend(otpReuestType) {
        this.otpReuestTypeButton = otpReuestType
        this.common.presentLoader();

        this.service.sendOtp(this.sendDataForOtpData, this.otpType, otpReuestType, this.service.redeemRequest, this.resetRequest).subscribe(res => {
            //console.log(' mobile', res);
            // if (this.platform.is('cordova')) {
            //     if (this.platform.is('android')) {
            //         this.otpStartWatch();
            //     }

            // }
            if (res) {
                if (!this.redeem) {
                    this.startCountdown(COUNT_NUMBER.COUNT_31)
                }
                this.common.dismissLoader();
                this.otpResData = res
                this.resendEnable = true
                this.otpFeildEnabled = true
                this.sendVlaueEditprofile = true
                this.service.redeemRequest = false
                this.sendDataonReedem(res)
            }
            else {
                this.common.dismissLoader();
            }

        }, err => {
            this.common.dismissLoader();
            ////console.log('err--->>', JSON.stringify(err));
        })
    }

    //**This funtion send data to activation page using event emitter */
    sendDataonReedem(res) {
        this.sendRedeemInfo.emit(res);

    }

    //**OTP VERIFI FUNTION */
    optVerifyFun(otpEntered) {
        if (this.service.resendredeemOtp == true) {
            console.log("hit redeem only")
            this.otpResData = this.service.resendRedeemDetals
        }

        if (!this.otpResData) {
            this.translate.get(OTP_COMPONENT.otp_MSG).subscribe((res: string) => {
                this.common.presentToast(res);
            });
            return;
        } else {
            this.common.presentLoader();
            this.service.optVerfiyApi(this.otpResData, otpEntered, this.sendDataForOtpData, this.otpType).subscribe(res => {
                if (res) {
                    this.common.dismissLoader();
                    const sendData = res.statusCode
                    res.otpType = this.otpType;
                    this.openReservationOtpVerified.emit(res);
                    this.otpSendFun(sendData, this.otpType)
                    this.sendEmailEditProfile(this.sendVlaueEditprofile)
                    this.service.resendRedeemDetals = ''
                    this.service.resendredeemOtp = false
                }
                else {
                    this.service.resendredeemOtp = false
                    this.common.dismissLoader();
                }
            }, err => {
                this.service.resendredeemOtp = false
                this.common.dismissLoader();
            })
        }
    }

    //**This funtion send data to activation page using event emitter */
    otpSendFun(code: string, otpMethod) {
        this.otpValidCode.emit(code);
        this.otpMthodType.emit(otpMethod);
    }

    sendEmailEditProfile(updateInfo: boolean) {
        this.emailEditProfileUpdate.emit(updateInfo)
    }


    onOtpChange(event) {
        if (event.length == parseInt(COUNT.COUNT_4)) {
            this.otpEntered = event;
            this.optVerifyFun(this.otpEntered);

            if (this.redeem) {
                this.reedemForEnableButton = true
                this.EnableRedeemButton(this.reedemForEnableButton, this.otpEntered)
            }
            else {
                this.reedemForEnableButton = false
                this.EnableRedeemButton(this.reedemForEnableButton, this.otpEntered)
            }

        } else {
            this.otpEntered = JAVASCRIPT.BLANK;
        }
    }


    startCountdown(seconds) {
        if (this.otpReuestTypeButton == 'resendOtp') {
            this.enableResendAfterCount = false

        }
        //console.log("hit Dign")
        let counter = seconds;
        const interval = setInterval(() => {
            //console.log(counter);
            counter--;
            this.countDown = "00:" + this.zeroPad(counter, 2);
            if (counter < 1) {
                this.countDown = "";
                this.enableResendAfterCount = true
                clearInterval(interval);
                //console.log('Ding!');
            }
        }, 1000);
    }

    private zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }



}
