import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-slot-wrap-window',
  templateUrl: './time-slot-wrap-window.component.html',
  styleUrls: ['./time-slot-wrap-window.component.scss']
})
export class TimeSlotWrapWindowComponent implements OnInit {
  @Input('deliveryTimeValues') deliveryTimeValues: any;
  @Input('lookIntoObject') lookIntoObject: any;
  @Output() deleteWindowEvent = new EventEmitter<string>();
  weekDays: any;
  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.weekDays = this.translateService.instant("setupRules.weekDays");
  }

  showDays(index: any) {
    if (index <= this.weekDays.length) {
      return this.weekDays[index].full;
    }
  }

  deleteWindow(index: any, lookIntoObject: any) {
    let obj = {index: index, lookIntoObject: lookIntoObject};
    this.deleteWindowEvent.emit(JSON.stringify(obj));
  }

}
