import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
declare var $: any

@Component({
  selector: 'app-handle-with-care',
  templateUrl: './handle-with-care.component.html',
  styleUrls: ['./handle-with-care.component.scss'],
})
export class HandleWithCareComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }
  instanceData:any
  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  onClick() {
    this.modalCtrl.dismiss({
      'sendDataInstance':this.instanceData
    });
  }
}
