import { Injectable } from '@angular/core';
import { LOGIN_PAGE } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthServService {

  constructor() { }

  IsloggedIn() {
    return localStorage.getItem(LOGIN_PAGE.loggedUser);
  }


}
