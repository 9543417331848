import { AppStateService } from './app-state.service';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpWrapperService, HttpInputData } from './../core/http/http-wrapper.service';
import { appApiResources, LOGIN_PAGE, storageKeys } from './../../app.constants';
import { Observable } from 'rxjs/Rx';
import { EncryptionService } from '../core/encryption/encryption.service';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { EventService } from '../event/event.service';
import { Market } from '@ionic-native/market/ngx';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
@Injectable({
  providedIn: 'root'
})
/**
 * Loads the App Init from network/storage to App state
 */
export class InitService {
  loadingDialog: any = null;
  todayDate = new Date().getTime();
  environment = null;
  uuid = null;
  userType;
  storeResponse: any;
  beforeEncryKey: { beforesecureKey: any, beforesecureIVKey: any };
  ionicStorageData: any
  enableTosaveLoginData: boolean;
  hidesplashScreen: boolean;
  deiviceStatus: any;
  apploginCheck: string;
  deviceToken: string;

  constructor(
    private httpWrapper: HttpWrapperService,
    private appState: AppStateService,
    private splashScreen: SplashScreen,
    public navCtrl: NavController,
    private router: Router,
    public encryptService: EncryptionService,
    public common: CommonService,
    public storageService: StorageService,
    public event: EventService,
    private platform: Platform,
    public storage: StorageService,
    private translate: TranslateService,
    private market: Market,
    public alertController: AlertController,
    public modalController: ModalController,) {
    this.environment = environment;
  }

  getInitApp(type): Observable<any> {
    var url;
    if (type == "firstTimeUser") {
      url = appApiResources.appInit;
      return this.httpWrapper
        .get(url)
        .map((response: any) => {
          let currentTimestamp = new Date().getTime();
          var stringForm = currentTimestamp.toString();
          localStorage.setItem('currentTime', stringForm)

          return response;
        })
        .catch((error: Response) => this.handleError(error)
        )
        .finally(() => { });
    } else if (type == "checkUpdatedData") {
      let currentTimestamp = localStorage.getItem('currentTime') || new Date().getTime();
      url = appApiResources.appInit + "?lastReadTime=" + currentTimestamp;
      return this.httpWrapper
        .get(url)
        .map((response: any) => {
          if (this.deiviceStatus == true) {
            // this.checkBuildVersion()
          }
          return response;
        })
        .catch((error: Response) => this.handleError(error))
        .finally(() => { });
    } else {
    }
  }

  async encryptiongetInitApp() {
    const appData = this.appState.getAppData();
    console.log("appData",appData)
    if( appData.devicCheckBase64) {
      this.deviceToken = appData.devicCheckBase64.saveDevicecheckBase64
    }

    let options = new HttpInputData();
    if (this.platform.is("cordova")) { 
      var datapass = {
        "device_token":this.deviceToken
      }
      
    
    } else {
      var datapass = {
        "device_token":"statictokenfsfsdfs2342342322"
      }
    }
   
    return new Promise((resolve, reject) => {
      this.httpWrapper.postHeader('program/anonymouskey',datapass,options,true).subscribe(async (res: any) => {
        console.log("ress anomus=>", res)
        console.log("header res=>" ,res.headers.get("csrftoken"))
        if (res) {
          resolve(res);
          this.encryptService.beforesecureKeyValue = await res['body'].secureKey;
          this.encryptService.beforesecureIVKeyValue = await res['body'].secureIV;
          this.deiviceStatus = res['body'].devicecheck
          if (this.platform.is("cordova")) {
            if (res['body'].devicecheck == false) {
              this.deviceCheckAlert();
            }
           
          }
          this.appState.saveCsrfToken(res.headers.get("csrftoken"));
          this.saveCsrfToken();
        }
      },
        (error: any) => {
          this.splashScreen.hide();
          this.common.dismissLoaderInit()
          //console.log("errorerror", error)
          resolve(error)
        }
      )
    });
  }

  async deviceCheckAlert() {
    const errorAlert = await this.alertController.create({
      mode: "ios",
      backdropDismiss: false,
      message: "Your device is not authorized for this app.",
      cssClass: "alertCustomCss",
    });
    await errorAlert.present();
  }

  // Save CSRF Token  
  saveCsrfToken() {
    this.ionicStorageData = this.appState.getAppData();
  }

  authenticatekeyApi() {
    let options = new HttpInputData();
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(`program/authenticatekey`).subscribe(
        async (res) => {
          this.common.dashboardApi = true
          //console.log("what authticateApi ress==>>" + JSON.stringify(res))
          this.encryptService.afterescureKeyValue = res.secureKey;
          this.encryptService.afterescureIVKeyValue = res.secureIV;
          await this.appState.loadAppData();

          this.logInCheckFun();
        },
        (error: any) => {
          this.splashScreen.hide();

          this.common.errorHandler(error);
          resolve(error)
        }
      )
    });
  }


  public handleError(error: Response) {

    this.splashScreen.hide();
    this.common.dismissLoaderInit()
    return Observable.throw(error);
  }

  async initializeApp() {
    const appData = await this.appState.getAppData();
    //console.log("devicCheckBase64", appData)
    if (this.platform.is('cordova')) {
      this.encryptiongetInitApp()
    } else {
      await this.encryptiongetInitApp()
    }
  }

  async checkAppStateData() {
    var self = this;
    let isLoaded = await this.appState.loadAppData();
    return new Promise((resolve, reject) => {
      if (!isLoaded && !this.ionicStorageData.loginCheck) {
        this.getInitApp('firstTimeUser').subscribe(
          response => {
            if (response) {
              self.appState.saveAppData(response);
              // if( response.collapseAble){
              //   localStorage.setItem(storageKeys.welcomeButtonStatus, response.collapseAble.isSSO)
              // }
              if (this.deiviceStatus == true) {
                // this.checkBuildVersion()
              }
              // this.event.publish('dataLoadinit', '');
              resolve(response);
            }
          },
          error => {
            console.log("InitilaiseApp_error: ", error);
            this.common.errorHandler(error);
          });
      }
      else {
        this.getInitApp('checkUpdatedData').subscribe(
          response => {
            if (response) {
              if (Object.entries(response).length === 0) {
                this.event.publish('dataLoadinit', '');
                resolve(response);
                // //console.log("Blank app init ka data ")
              } else {
                resolve(response);
                // //console.log("New init app data is coming.. ")
                self.appState.saveAppData(response);
                // localStorage.setItem(storageKeys.welcomeButtonStatus, response.collapseAble.isSSO)
                // this.event.publish('dataLoadinit', '');
                let currentTimestamp = new Date().getTime();
                var stringForm = currentTimestamp.toString();
                localStorage.setItem('currentTime', stringForm)
                if (this.deiviceStatus == true) {
                  // this.checkBuildVersion()
                }

              }
            }
          },
          error => {
            this.common.dismissLoaderInit()
            this.common.errorHandler(error);
            //console.log("InitilaiseApp_error: ", error);
          });
        //console.log("InitilaiseApp last console ");
      }
    })
  }

  async checkFirstTimeUserStateData() {
    // this.common.presentLoader()
    var self = this;
    return new Promise((resolve, reject) => {
      this.getInitApp('firstTimeUser').subscribe(
        response => {
          if (response) {
            // //console.log('New Data Update>>>>>', response)
            self.appState.saveAppData(response);
            resolve(response);
          }
          // this.common.dismissLoaderInit()
        },
        error => {
          // this.common.dismissLoaderInit()
          //console.log("InitilaiseApp_error: ", error);
        });
    })
  }

  afterloginAuthFun() {
    if (this.ionicStorageData.loginCheck) {
      this.storageService.checkUserLoged = true
      this.encryptService.checkDecyptFun = true
      // //console.log("this point when reach 2nd checkUpdatedData ")
      this.authenticatekeyApi()
    }
  }

  async logInCheckFun() {
    let checkOutlet = JSON.parse(await this.storage.getData(storageKeys.selecOutlet))
    // console.log("checkOutlet", checkOutlet)
    // console.log("this.ionicStorageData.loginCheck", this.ionicStorageData.loginCheck)
    if (this.ionicStorageData.loginCheck) {
      this.splashScreen.hide();
      // this.navCtrl.navigateRoot('dashboard')
      if (this.common.dashboardApi) {
        this.event.publish('allDashboardApi', '');
      }
    } else if (checkOutlet != null) {
      this.splashScreen.hide();
      // console.log("select-outlet", checkOutlet)
      this.router.navigateByUrl('select-outlet')
    }
    else {
      this.splashScreen.hide();
      // this.router.navigateByUrl('welcome-page')
    }
  }

  checkCSRFTokenInit(): Observable<any> {
    //console.log('checkCSRFToken=======>>>>>>>>>>>')

    const appData = this.appState.getAppData();
    console.log("appData",appData)
    if( appData.devicCheckBase64) {
      this.deviceToken = appData.devicCheckBase64.saveDevicecheckBase64
    }

    let options = new HttpInputData();
    if (this.platform.is("cordova")) { 
      var datapass = {
        "device_token":this.deviceToken
      }
    } else {
      var datapass = {
        "device_token":"asbhdjkhdkjshjdkhskjdhskjd34"
      }
    }

    return this.httpWrapper.postHeader('program/anonymouskey', datapass,options, true)
      .map((res: any) => {
        if (res) {
          this.encryptService.beforesecureKeyValue = res['body'].secureKey;
          this.encryptService.beforesecureIVKeyValue = res['body'].secureIV;
          this.appState.saveCsrfToken(res.headers.get("csrftoken"));
        }
      })
  }



}
