import { Injectable } from '@angular/core';
import { HttpClient, HttpClient as angularHttp, HttpHeaders, HttpParams } from "@angular/common/http";
import { checkAvailability } from '@ionic-native/core';
import { from, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { appApiResources, COMMOM_MESSAGES_SERVICES } from 'src/app/app.constants';
import { CommonService } from 'src/app/services/common.service';
import { AppStateService } from '../../init/app-state.service';
import { Platform, AlertController } from '@ionic/angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
/**
 * Http Service class for network operations.
 */
export class HttpWrapperService {

  protected nativeIsAvailable: boolean | null = null;

  private nativeHttp: HTTP;
  private angularHttp: HttpClient;
  checkNative: boolean;


  constructor(private native: HTTP, private angular: angularHttp,
    private appState: AppStateService,
    public platform: Platform,
    public alertCtrl: AlertController,
    public commomServ: CommonService) {
    this.nativeHttp = native;
    this.angularHttp = angular;


  }
  async internetOff() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: COMMOM_MESSAGES_SERVICES.againInternetOffMessage,
      buttons: ['Got It']
    });
    await alert.present();
  }

  private httpHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',

    })
  };

  public isNativeHttpAvailable() {
    if (this.nativeIsAvailable === null) {
      this.nativeIsAvailable = checkAvailability('cordova.plugin.http') === true || checkAvailability('cordovaHTTP') === true;
    }
    // this.checkNative = false
    // return false;
                

    return this.nativeIsAvailable;
  }

  /**
   * Make a get request.
   * @param url - URL for network operation.
   * @param addAuth - whether needs to add the session token.
   * @param options - The headers and params to set for this request
   */

  //  Normal get method 
  public get(url: string, options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {
    console.log("url==>>" + url)
    console.log("options==>>" + JSON.stringify(options))

    if (this.commomServ.internetStatus) {
      url = this.createURL(url, options);
      console.log("url createURL ==>>" + url)
      // //console.log("Data NATIVE ==>> options GET " + JSON.stringify(options))
      if (this.isNativeHttpAvailable()) {
        // //console.log("1st STEP GET METHOD")
        return from(this.nativeHttp.get(url, this.parseParamsForNativeHttp(options), this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
          //console.log("Data NATIVE ==>> all res GET " + JSON.stringify(res))
          // //console.log("Data NATIVE ==>> GET " + JSON.stringify(res.data))
          // //console.log("Data NATIVE ==>> headers headers GET " + JSON.stringify(res.headers))
          return this.parseBodyFromNativeHttpResponse(res, options);
        })
          )};
      if (isHeaderRequired) {
        return this.angularHttp.get(url, { headers: options.headers, params: options.params, observe: "response" });
      } else if (options) {
        return this.angularHttp.get(url, { headers: options.headers, params: options.params });
      } else {
        return this.angularHttp.get(url);
      }
    } else {
      //console.log("else wrapper => normal get api")
      this.commomServ.dismissLoader()
      this.internetOff()

    }
  }

  // Http native For AEM api
  public getAemApi(url: string, options?: HttpInputData): Observable<any> {
    if (this.isNativeHttpAvailable()) {
      return from(this.nativeHttp.get(url, this.parseParamsForNativeHttp(options), this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
        return this.parseBodyFromNativeHttpResponse(res, options);

      })
       ) } else {
      //console.log("else wrapper => normal get api")
      this.commomServ.dismissLoader()
      this.commomServ.presentToast(COMMOM_MESSAGES_SERVICES.againInternetOffMessage);

    }
    return this.angularHttp.get(url, options);


  }




  // Header  Get method 
  public Headerget(url: string, options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {
    console.log("url==>>" + url)
    //console.log("options==>>" + JSON.stringify(options))

    if (this.commomServ.internetStatus) {
      url = this.createURL(url, options);
      // //console.log("url createURL ==>>" + url)
      // //console.log("Data NATIVE ==>> options GET " + JSON.stringify(options))
      if (this.isNativeHttpAvailable()) {
        ////console.log("1st STEP GET METHOD")
        return from(this.nativeHttp.get(url, this.parseParamsForNativeHttp(options), this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
          console.log("Data NATIVE ==>> all res GET " + JSON.stringify(res))
          console.log("Data NATIVE ==>> GET " + JSON.stringify(res.data))
          // //console.log("Data NATIVE ==>> options GET " + JSON.stringify(options))
          // //console.log("Data NATIVE ==>> headers headers GET " + JSON.stringify(res.headers))
          // return this.parseBodyFromNativeHttpResponse(res, options);

          return {
            json() {
              console.log("Data NATIVE ==>> GET " + JSON.stringify(res.data))
              return JSON.parse(res.data);
            },
            text(ignoredEncodingHint) {
              return res.data.toString();
            },
            body: this.parseBodyFromNativeHttpResponse(res, options),
            headers: new Headers(res.headers)
          }
        })
        )}
      if (isHeaderRequired) {
        return this.angularHttp.get(url, { headers: options.headers, params: options.params, observe: "response" });
      } else if (options) {
        return this.angularHttp.get(url, { headers: options.headers, params: options.params });
      } else {
        return this.angularHttp.get(url);
      }
    } else {
      //console.log("else wrapper =>")
    }
  }




  /**
 * Make a delete request.
 * @param url - URL for network operation.
 ** @param body - The body of the request
 * @param addAuth - whether needs to add the session token.
 * @param options - The headers and params to set for this request
 */
  public delete(url: string, body:any,options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {
    console.log("body=>>",body)
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: body
  };

    if (this.commomServ.internetStatus) {
      url = this.createURL(url, options);
      if (this.isNativeHttpAvailable()) {
        return from(
          this.nativeHttp.delete(
            url,
            this.parseParamsForNativeHttp(options),
            this.parseHeadersForNativeHttp(url, body)
          )
        ).pipe(map((res: any) => {
          return {
            json() {
              return JSON.parse(res.data);
            },
            text(ignoredEncodingHint) {
              return res.data.toString();
            },
            body: this.parseBodyFromNativeHttpResponse(res, options),
            headers: new Headers(res.headers)
          }
        })
        )}
        if (isHeaderRequired) {
          return this.angularHttp.delete(url, { headers: options.headers, params: options.params, observe: "response" });
        } else if (options) {
          return this.angularHttp.delete(url, { headers: options.headers, params: options.params });
        } else {
          console.log("hit 3")
          console.log("url",url , "httpOptions",httpOptions)
          return this.angularHttp.delete(url,httpOptions.body );
        }
    } else {
      this.commomServ.dismissLoader()
      this.internetOff()
    }
  }


  /**
   * Make a POST request
   * 
   * @param url - The url to send the request to
   * @param body - The body of the request
   * @param addAuth whether needs to add the session token.
   * @param options - The headers and params to set for this request
   */

  public post(url: string, body: any, options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {
    console.log("Data NATIVE ==>> options Post " + JSON.stringify(url))
    console.log("Data NATIVE ==>> options Post " + JSON.stringify(body))

    if (this.commomServ.internetStatus == true) {
      //console.log("wrapper this.commomServ.internetStatus", this.commomServ.internetStatus)
      url = appApiResources.baseUrl + url;
      if (this.isNativeHttpAvailable()) {
        this.nativeHttp.setDataSerializer('json');
        return from(this.nativeHttp.post(url, body, this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
          return this.parseBodyFromNativeHttpResponse(res, options);
        })
        )}

      if (isHeaderRequired) {
        return this.angularHttp.post(url, body, { headers: options.headers, params: options.params, observe: "response" });
      } else {
        return this.angularHttp.post(url, body, { headers: options.headers, params: options.params });
      }

    } else {
      this.commomServ.dismissLoader()
      this.internetOff()
    }
  }

  // Http Wrapper For AEM API 
  public postAemData(url: string, body, options?: HttpInputData): Observable<any> {
    var response;
    if (this.isNativeHttpAvailable()) {
      // //console.log('Inside aem ');
      this.nativeHttp.setDataSerializer('json');
      return from(this.nativeHttp.post(url, body, this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
        // //console.log('AEM_PostResp_Succ',res);
        // response = JSON.parse(res.data)
        return this.parseAemBodyFromNativeHttpResponse(res, options);
        // return {
        //   json() {
        //     return JSON.parse(res.data);
        //   },
        //   text(ignoredEncodingHint) {
        //     return res.data.toString();
        //   },
        //   body: this.parseBodyFromNativeHttpResponse(res, options),
        //   headers: new Headers(res.headers)
        // }
        // return response;
      }, err => {
        console.error("AEM error wraper", err);
      })
      // .catch((error: Response) => this.commomServ.errorHandler(error));
      )}

    else {
      // //console.log("HITT PRINCE AEM")
      return this.angularHttp.post(url, body);
    }


  }

  public postHeader(url: string, body: any, options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {

    if (this.commomServ.internetStatus) {
      url = appApiResources.baseUrl + url;
      console.log(" postHeader METHOD URL ", url)
      console.log("Data NATIVE ==>>  headero bodyPost " + JSON.stringify(body))
      if (this.isNativeHttpAvailable()) {
        this.nativeHttp.setDataSerializer('json');

        return from(this.nativeHttp.post(url, body, this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
          //console.log("Data NATIVE ==>> header all res Post " + JSON.stringify(res))
          // //console.log("Data NATIVE ==>>header  Post " + JSON.stringify(res.data))
          // //console.log("Data NATIVE ==>>  headeroptions Post " + JSON.stringify(options))
          // //console.log("Data NATIVE ==>> postHeader headers headers Post " + JSON.stringify(res.headers))
          return {
            json() {

              return JSON.parse(res.data);
            },
            text(ignoredEncodingHint) {
              return res.data.toString();
            },
            body: this.parseBodyFromNativeHttpResponse(res, options),
            headers: new Headers(res.headers)
          }

        })
        )}

      if (isHeaderRequired) {
        return this.angularHttp.post(url, body, { headers: options.headers, params: options.params, observe: "response" });
      } else {
        return this.angularHttp.post(url, body, { headers: options.headers, params: options.params });
      }

    } else {
      this.commomServ.dismissLoader()
      this.internetOff()
    }
  }



  public put(url: string, body: any, options?: HttpInputData, isHeaderRequired?: boolean): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
    
    if (this.commomServ.internetStatus) {
      url = appApiResources.baseUrl + url;
      console.log("url from put", url, body, options);
      if (this.isNativeHttpAvailable()) {
        this.nativeHttp.setDataSerializer('json');
        return from(this.nativeHttp.put(url, body, this.parseHeadersForNativeHttp(url, options))).pipe(map((res: any) => {
          return this.parseBodyFromNativeHttpResponse(res, options);
          // return {
          //   json() {
          //     return JSON.parse(res.data);
          //   },
          //   text(ignoredEncodingHint) {
          //     return res.data.toString();
          //   },
          //   body: this.parseBodyFromNativeHttpResponse(res, options),
          //   headers: new Headers(res.headers)
          // }
        })
        )}

      if (isHeaderRequired) {
        return this.angularHttp.put(url, body, { headers: options.headers, params: options.params, observe: "response" });
      } else if(options){
        return this.angularHttp.put(url, body, { headers: options.headers, params: options.params });
      }else {
        console.log("hit 3")
        console.log("url",url , "httpOptions",httpOptions,body.body)
        return this.angularHttp.put(url,body.body );
      }

    } else {
      this.commomServ.dismissLoader()
      this.internetOff()
    }
  }



  private parseHeadersForNativeHttp(url: string, options: any) {
    ////console.log('parseHeadersForNativeHttp_URL ==>', url, options);
    let headers: Headers | {} | null = options !== undefined && options.headers !== undefined ? options.headers : {};
    headers = this.gethttpHeader(url);
    ////console.log("wraper HEader=>>" + JSON.stringify(headers))
    if (headers instanceof Headers) {
      let newHeaders: any = {};
      headers.forEach(function (value, name) {
        newHeaders[name.toString()] = value.toString();

      });
      headers = newHeaders;
    }
    console.log("Native HEADER==>>" + JSON.stringify(headers))
    return headers;
  }


  private gethttpHeader(url: string) {
    ////console.log('getHttpHeaderOptions_URL ==>', url);
    let headers = {};

    if (url.indexOf('assets/i18n') === -1 && url.indexOf(appApiResources.aemUrl) === -1) {
      ////console.log("aemmmSetp1")
      headers = this.commomServ.headerSendHttp();
      const appData = this.appState.getAppData();

      if (appData && appData.csrftoken) {
        headers['csrftoken'] = appData.csrftoken;
      }


      if (url.indexOf("anonymouskey") != -1 && this.platform.is('cordova')) {
        //  Last modify 17-aug-21 ... THA-572 Anonymous API change to post

        // if (appData.devicCheckBase64) {
        //   headers['device_token'] = appData.devicCheckBase64.saveDevicecheckBase64;
        // }
        // if (this.platform.is('ios')) {
        //   headers['device_type'] = 'IOS';
        // } else {
        //   headers['X-Requested-With'] = 'com.tlcgroup.hotel.cmhotel';
        //   headers['device_type'] = 'ANDROID';
        // }
      }

      if (this.commomServ.outletIDD) {
        headers['OutletID'] = this.commomServ.outletIDD;
      } else {
        if (appData && appData.userDetails) {
          if (appData.userDetails.outletId) {
            headers['OutletID'] = appData.userDetails.outletId;
          }
        }
      }


      if (appData && appData.userDetails) {
        if (appData.userDetails.employeeId) {
          headers['employeeId'] = appData.userDetails.employeeId;
        }
      }

      if (appData && appData.employeeidHeader) {
        if (appData.employeeidHeader) {
          headers['employeeId'] = appData.employeeidHeader;
        }

      }

      if (appData && appData.sessionToken) {
        if (appData.sessionToken) {
          headers['SessionIDToken'] = appData.sessionToken;
        }
      }


      if (this.platform.is('ios')) {
        headers['device_type'] = 'IOS';
      } else {
        headers['X-Requested-With'] = 'com.tlcgroup.hotel.cmhotel';
        headers['device_type'] = 'ANDROID';
      }

      console.log('getHttpHeaderOptions ==>', headers);
      ////console.log("aemmmSetp2")
      return headers;
    }
  }



  private parseParamsForNativeHttp(options: any) {
    //return options && options.params ? options.params : {};
    let params: HttpParams = options !== undefined && options.params !== undefined ? options.params : {};
    if (params instanceof HttpParams) {
      let newParams: any = {};
      params.keys().forEach(function (key) {
        newParams[key] = params.get(key).toString();
      });
      params = newParams;
    }
    return params;
  }



  private parseAemBodyFromNativeHttpResponse(res: any, options: any) {
    let response = null;

    if (res.data) {
      if (options === undefined || options.responseType === undefined || options.responseType === 'json') {
        response = JSON.parse(res.data);
      }
      response = res.data;
    }
    return response;
  }



  private parseBodyFromNativeHttpResponse(res: any, options: any) {
    let response = null;
    if (res.data) {
      // if (options === undefined || options.responseType === undefined || options.responseType === 'json') {
      //   response = JSON.parse(res.data);
      // }
      response = JSON.parse(res.data);
    }
    return response;
  }

  private createURL(url: string, options?: HttpInputData) {
    url = appApiResources.baseUrl + url;
    if (options && options.uriParams) {
      url += "/";
      var index = 0;
      var length = Object.keys(options.uriParams).length
      Object.keys(options.uriParams).forEach(key => {
        url.replace("{" + key + "}", options.uriParams[key]);
        if (++index != length) {
          url += "/";
        }
      });
    }
    return url;
  }
}

export class HttpInputData {
  headers: HttpHeaders = null;
  uriParams: Object = null;
  params: HttpParams = null;
  authentication: boolean = false;
}



