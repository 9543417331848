import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { TimestampConvertPipe } from './timestamp-convert.pipe';

@NgModule({
    declarations:[
        TimestampConvertPipe
    ],
    imports:[CommonModule],
    exports:[
        TimestampConvertPipe
    ]
})

export class MainPipe{}