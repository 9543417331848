import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-select-membership',
  templateUrl: './select-membership.component.html',
  styleUrls: ['./select-membership.component.scss'],
})
export class SelectMembershipComponent implements OnInit {
  @Input() memberships: any;
  @Input() response: any;
  
  memberProfileData: any;

  constructor(
    private modalController: ModalController,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
      console.log("memberProfileData",this.memberships)
      console.log("response",this.response)
  }

  closeModal(data?: any, role?: string, id?: string) {
    this.modalController.dismiss(data, role, id);
  }

  cardSelect(items, i) {
    this.memberProfileData = items;
  }

  continueWithSelectedCard() {
    this.closeModal(this.memberProfileData, 'membershipSelected');
  }

}
