import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appApiResources, ReservationPromocodeQueryString } from 'src/app/app.constants';
import { ReservationPayload } from 'src/app/pages/reservation/reservation.model';
import { CommonService } from '../common.service';
import { HttpInputData, HttpWrapperService } from '../core/http/http-wrapper.service';
import { AppStateService } from '../init/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class OpenReservationService {
  outletID: string;
  appData: any;


  constructor(
    private httpWrapper: HttpWrapperService,
    private commonService: CommonService,
    public appState: AppStateService,
  ) {

  }

  verifyMobile(mobileNumber: string) {
    const options = new HttpInputData();
    let httpParams = new HttpParams();
    httpParams = httpParams.set('mobile', mobileNumber);
    options.params = httpParams
    return this.httpWrapper.get(appApiResources.openNewReservation, options).map((res) => {
      if (res) {

        return res;
      }
    }).toPromise()
    // .catch((error) => {
    //   if(error.error.errorCode =='TLC-973') {

    //     return
    //   } else {
    //     this.commonService.errorHandler(error);

    //   }
    // }
    // )
  }

  getOutletList(employeeID: string) {
    const options = new HttpInputData();
    let httpParams = new HttpParams();
    httpParams = httpParams.set('employeesfid', employeeID);
    options.params = httpParams;
    return this.httpWrapper.get(appApiResources.outletList, options).map(
      (res) => {
        if (res) {
          return res
        }
      }
    ).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      })
  }

  applyPromocode(queryString: ReservationPromocodeQueryString) {
    const options = new HttpInputData();
    let httpParams = new HttpParams();
    Object.keys(queryString).forEach((key) => {
      httpParams = httpParams.set(key, queryString[key])
    });
    options.params = httpParams;
    return this.httpWrapper.get(appApiResources.promocodeForReservation, options).map((res) => {
      if (res) {
        return res;
      }
    }).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      })
  }

  getDetailsOfOutletAndBenefit(id: string, type: "Outlet" | "Certificate" | "Card" | "Promocode") {

    console.log("id=>>", id)
    console.log("type=>>", type)
    // let options = new HttpInputData();
    // let httpParams = new HttpParams();
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    httpParams = httpParams.set("uniqueIdentifier", id);
    httpParams = httpParams.set("offerType", type);
    options.params = httpParams;

    return this.httpWrapper.get(appApiResources.detailApi, options).map((res) => {
      if (res) {
        return res
      }
    }).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      });
  }

  doReservation(payload: ReservationPayload, queryString: { outletID: string, membershipNumber?: string }) {
    const options = new HttpInputData();
    let url = appApiResources.createReservation;

    if (queryString.outletID) {
      url = url + `?outletID=${queryString.outletID}`
    }

    if (queryString.membershipNumber) {
      url = url + `&membershipNumber=${queryString.membershipNumber}`
    }

    return this.httpWrapper.post(url, payload, options).map((res) => {
      if (res) {
        return res;
      }
    }).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      });
  }
}
