export class Category {
    categoryName: any;
    categoryId: any;
    cIsNotAvailable: any;
    categoryImage: any;

    constructor(attributes: Object = {}) {
        Object.assign(this, attributes);
    }
    
}
export class Widget {
    widgetId: any;
    widgetName: any;
    widgetSequenceId: any;
    widgetImageUrl: any;
    tax: any;
    wIsNotAvailable: any;
    categories: Array<Category>;

    constructor(attributes: any) {
        Object.assign(this, attributes);
        this.categories = this.categories.map(e => new Category(e));
    }
}

export class WidgetList {
    widgets: Array<Widget> = [];
    constructor(attributes: any) {
        Object.assign(this, attributes);
    }

    getWidget(value: any): Array<Category> {
        if (value) {
            let widgets = this.widgets.find(e => e.widgetId == value);
            return widgets.categories;
        }
    }
}