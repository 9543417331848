import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './auth/authguard.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/welcome-page/welcome-page.module').then(m => m.WelcomePagePageModule), canActivate: [AuthguardGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },

  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  },
  {
    path: 'redemption-log',
    loadChildren: () => import('./pages/redemption-log/redemption-log.module').then(m => m.RedemptionLogPageModule)
  },
  {
    path: 'view-reservations',
    loadChildren: () => import('./pages/dashboard/view-reservations/view-reservations.module').then(m => m.ViewReservationsPageModule)
  },
  {
    path: 'reservation-master',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-master.module').then(m => m.ReservationMasterPageModule)
  },
  {
    path: 'member-search',
    loadChildren: () => import('./pages/dashboard/member-search/member-search.module').then(m => m.MemberSearchPageModule)
  },
  {
    path: 'record-exception',
    loadChildren: () => import('./pages/dashboard/record-exception/record-exception.module').then(m => m.RecordExceptionPageModule)
  },
  {
    path: 'open-reservation',
    loadChildren: () => import('./pages/open-reservation/open-reservation.module').then(m => m.OpenReservationPageModule)
  },
  {
    path: 'outlet-master',
    loadChildren: () => import('./pages/outlet-master/outlet-master.module').then(m => m.OutletMasterPageModule)
  },
  {
    path: 'select-outlet',
    loadChildren: () => import('./pages/select-outlet/select-outlet.module').then(m => m.SelectOutletPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'welcome-page',
    loadChildren: () => import('./pages/welcome-page/welcome-page.module').then(m => m.WelcomePagePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'activate-account',
    loadChildren: () => import('./pages/activate-account/activate-account.module').then(m => m.ActivateAccountPageModule)
  },
  {
    path: 'setup-profile',
    loadChildren: () => import('./pages/setup-profile/setup-profile.module').then(m => m.SetupProfilePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'reservations-awaiting-confirmation',
    loadChildren: () => import('./pages/dashboard/reservations-awaiting-confirmation/reservations-awaiting-confirmation.module').then(m => m.ReservationsAwaitingConfirmationPageModule)
  },
  {
    path: 'manage-reservation',
    loadChildren: () => import('./pages/dashboard/manage-reservation/manage-reservation.module').then(m => m.ManageReservationPageModule)
  },
  {
    path: 'certificate-detail',
    loadChildren: () => import('./pages/dashboard/certificate-detail/certificate-detail.module').then(m => m.CertificateDetailPageModule)
  },
  {
    path: 'guest-profile',
    loadChildren: () => import('./pages/dashboard/guest-profile/guest-profile.module').then(m => m.GuestProfilePageModule)
  },
  {
    path: 'available-certificates',
    loadChildren: () => import('./pages/dashboard/available-certificates/available-certificates.module').then(m => m.AvailableCertificatesPageModule)
  },
  {
    path: 'available-certificates',
    loadChildren: () => import('./pages/dashboard/available-certificates/available-certificates.module').then(m => m.AvailableCertificatesPageModule)
  },
  {
    path: 'card-benefits',
    loadChildren: () => import('./pages/dashboard/card-benefits/card-benefits.module').then(m => m.CardBenefitsPageModule)
  },
  {
    path: 'points',
    loadChildren: () => import('./pages/dashboard/points/points.module').then(m => m.PointsPageModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./pages/dashboard/preferences/preferences.module').then(m => m.PreferencesPageModule)
  },
  {
    path: 'handle-with-care',
    loadChildren: () => import('./pages/dashboard/handle-with-care/handle-with-care.module').then(m => m.HandleWithCarePageModule)
  },
  {
    path: 'member-review',
    loadChildren: () => import('./pages/dashboard/member-review/member-review.module').then(m => m.MemberReviewPageModule)
  },
  {
    path: 'redeem-benefits',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeem-benefits.module').then(m => m.RedeemBenefitsPageModule)
  },
  {
    path: 'reason-exception',
    loadChildren: () => import('./pages/dashboard/record-exception/reason-exception/reason-exception.module').then(m => m.ReasonExceptionPageModule)
  },
  {
    path: 'awaiting-arrival',
    loadChildren: () => import('./pages/dashboard/awaiting-arrival/awaiting-arrival.module').then(m => m.AwaitingArrivalPageModule)
  },
  {
    path: 'walk-in',
    loadChildren: () => import('./pages/dashboard/walk-in/walk-in.module').then(m => m.WalkInPageModule)
  },

  {
    path: 'close-cheque',
    loadChildren: () => import('./pages/dashboard/close-cheque/close-cheque.module').then(m => m.CloseChequePageModule)
  },
  {
    path: 'in-progress',
    loadChildren: () => import('./pages/dashboard/in-progress/in-progress.module').then(m => m.InProgressPageModule)
  },
  {
    path: 'view-reservations',
    loadChildren: () => import('./pages/dashboard/view-reservations/view-reservations.module').then(m => m.ViewReservationsPageModule)
  },
  {
    path: 'reservation-master',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-master.module').then(m => m.ReservationMasterPageModule)
  },
  {
    path: 'redeemed',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeemed/redeemed.module').then(m => m.RedeemedPageModule)
  },
  {
    path: 'redeem-otp',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeem-otp/redeem-otp.module').then(m => m.RedeemOtpPageModule)
  },
  {
    path: 'redeem-qr',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeem-qr/redeem-qr.module').then(m => m.RedeemQrPageModule)
  },
  {
    path: 'add-club',
    loadChildren: () => import('./pages/dashboard/add-club/add-club.module').then(m => m.AddClubPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/dashboard/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'user-dashboard',
    loadChildren: () => import('./pages/dashboard/user-dashboard/user-dashboard.module').then(m => m.UserDashboardPageModule)
  },
  {
    path: 'spenders',
    loadChildren: () => import('./pages/dashboard/user-dashboard/spenders/spenders.module').then(m => m.SpendersPageModule)
  },
  {
    path: 'pos-audit',
    loadChildren: () => import('./pages/dashboard/user-dashboard/pos-audit/pos-audit.module').then(m => m.PosAuditPageModule)
  },
  {
    path: 'offer-cheque',
    loadChildren: () => import('./pages/dashboard/offer-cheque/offer-cheque.module').then(m => m.OfferChequePageModule)
  },
  {
    path: 'offer-details',
    loadChildren: () => import('./pages/dashboard/offer-details/offer-details.module').then(m => m.OfferDetailsPageModule)
  },
  {
    path: 'redemption-listing',
    loadChildren: () => import('./pages/redemption-listing/redemption-listing.module').then(m => m.RedemptionListingPageModule)
  },
  {
    path: 'outlet-details',
    loadChildren: () => import('./pages/outlet-details/outlet-details.module').then(m => m.OutletDetailsPageModule)
  },
  {
    path: 'activate-reservation',
    loadChildren: () => import('./pages/activate-reservation/activate-reservation.module').then(m => m.ActivateReservationPageModule)
  },
  {
    path: 'reservation-list',
    loadChildren: () => import('./pages/reservation-list/reservation-list.module').then(m => m.ReservationListPageModule)
  },
  {
    path: 'reservation-count',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-count/reservation-count.module').then(m => m.ReservationCountPageModule)
  },
  {
    path: 'reservation-block',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-block/reservation-block.module').then(m => m.ReservationBlockPageModule)
  },
  {
    path: 'reservation',
    loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationPageModule)
  },
  {
    path: 'view-outlet-master',
    loadChildren: () => import('./pages/outlet-master/view/view.module').then(m => m.ViewPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/mbowdelivery/orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./pages/mbowdelivery/order-details/order-details.module').then(m => m.OrderDetailsPageModule)
  },
  {
    path: 'user-details',
    loadChildren: () => import('./pages/mbowdelivery/user-details/user-details.module').then(m => m.UserDetailsPageModule)
  },
  {
    path: 'setup-outlet',
    loadChildren: () => import('./pages/mbowdelivery/setup-outlet/setup-outlet.module').then(m => m.SetupOutletPageModule)
  },
  {
    path: 'setup-rules',
    loadChildren: () => import('./pages/mbowdelivery/setup-rules/setup-rules.module').then(m => m.SetupRulesPageModule)
  },
  {
    path: 'setup-widget',
    loadChildren: () => import('./pages/mbowdelivery/setup-widget/setup-widget.module').then( m => m.SetupWidgetPageModule)
  },
  {
    path: 'setup-menu',
    loadChildren: () => import('./pages/mbowdelivery/setup-menu/setup-menu.module').then( m => m.SetupMenuPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/mbowdelivery/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'edit-menu',
    loadChildren: () => import('./pages/mbowdelivery/edit-menu/edit-menu.module').then( m => m.EditMenuPageModule)
  },
  {
    path: 'update-sequence',
    loadChildren: () => import('./pages/mbowdelivery/edit-menu/edit-menu.module').then( m => m.EditMenuPageModule)
  },
  {
    path: 'block-service',
    loadChildren: () => import('./pages/mbowdelivery/block-service/block-service.module').then( m => m.BlockServicePageModule)
  },
  {
    path: 'revenue-management',
    loadChildren: () => import('./pages/mbowdelivery/revenue-management/revenue-management.module').then( m => m.RevenueManagementPageModule)
  },
  {
    path: 'item-not-available',
    loadChildren: () => import('./pages/mbowdelivery/item-not-available/item-not-available.module').then( m => m.ItemNotAvailablePageModule)
  },
  {
    path: 'update-item',
    loadChildren: () => import('./pages/mbowdelivery/update-item/update-item.module').then( m => m.UpdateItemPageModule)
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }