import { Injectable } from '@angular/core';
import { CHAT } from 'src/app/app.constants';
import { CommonService } from './common.service';
import { AppStateService } from './init/app-state.service';
import { AndroidIosDevideCheckSafetynetService } from './android-iOS-deviceCheck-safetynet';
import { Platform } from '@ionic/angular';
import { InitService } from './init/init.service';

declare var window: any;
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  fullName: any;
  mobileNumber: any;

  constructor(private initService: InitService,
    public common: CommonService,
    public appState: AppStateService,
    public deviceCheckSafetynetService: AndroidIosDevideCheckSafetynetService,
    public platform: Platform) { }

  ////////initializechat
  initializechat() {
    let SalesforceSnapIns = window.cordova.plugins.SalesforceSnapIns;
    SalesforceSnapIns.initialize({
      liveAgentChat: {
        liveAgentPod: this.initService.environment.liveAgentPod,
        orgId: this.initService.environment.orgId,
        deploymentId: this.initService.environment.deploymentId,
        buttonId: this.initService.environment.buttonId
      },
      colors: {
        navbarBackground: CHAT.navbarBackground,
        navbarInverted: CHAT.navbarInverted,
        brandPrimary: CHAT.brandPrimary,
        brandSecondary: CHAT.brandSecondary,
        brandPrimaryInverted: CHAT.brandPrimaryInverted,
        brandSecondaryInverted: CHAT.brandSecondaryInverted,
        contrastPrimary: CHAT.contrastPrimary,
        contrastSecondary: CHAT.contrastSecondary,
        contrastTertiary: CHAT.contrastTertiary,
        contrastQuaternary: CHAT.contrastQuaternary,
        contrastInverted: CHAT.contrastInverted,
        feedbackPrimary: CHAT.feedbackPrimary,
        feedbackSecondary: CHAT.feedbackSecondary,
        feedbackTertiary: CHAT.feedbackTertiary,
        overlay: CHAT.overlay
      }
    });

  }

  //..Start With Visitor Chat here//
  startWithVisitorChat() {
    let SalesforceSnapIns = window.cordova.plugins.SalesforceSnapIns;
    SalesforceSnapIns.clearPrechatFields();
    SalesforceSnapIns.addPrechatField({
      type: CHAT.text,
      label: CHAT.Name,
      required: true
    });
    SalesforceSnapIns.addPrechatField({
      type: CHAT.text,
      label: CHAT.Mobile,
      required: true,
      keyboardType: SalesforceSnapIns.KEYBOARD_TYPE_NUMBER_PAD,
      autocorrectionType: SalesforceSnapIns.AUTOCORRECTION_TYPE_NO
    });
    SalesforceSnapIns.addPrechatField({
      type: CHAT.text,
      label: CHAT.Email,
      required: true,
      keyboardType: SalesforceSnapIns.KEYBOARD_TYPE_EMAIL_ADDRESS,
      autocorrectionType: SalesforceSnapIns.AUTOCORRECTION_TYPE_NO
    });
    let object = this;
    SalesforceSnapIns.determineAvailability(function (available) {
      if (available) {
        SalesforceSnapIns.openLiveAgentChat(function () {
        }, function () {
        });
      } else {
        object.common.agentUnavailable();
      }
    }, function (err) {
    });
  }

  liveChatWithAgent() {
    let SalesforceSnapIns = window.cordova.plugins.SalesforceSnapIns;
    let object = this;
    const appData = this.appState.getAppData();
    if (appData && appData.userDetailsForChat) {
      object.fullName = appData.userDetailsForChat.name,
        object.mobileNumber = appData.userDetailsForChat.mobileNumber
    }
    SalesforceSnapIns.clearPrechatFields();
    SalesforceSnapIns.addPrechatField({
      type: CHAT.hidden,
      label: CHAT.Customer_Name,
      value: object.fullName,
      required: false
    });

    SalesforceSnapIns.addPrechatField({
      type: CHAT.hidden,
      label: CHAT.Customer_Mobile,
      value: object.mobileNumber,
      required: false
    });

    SalesforceSnapIns.clearPrechatEntities();

    var accountEntity = {
      name: CHAT.Account,
      linkToEntityName: CHAT.Account,
      linkToEntityField: CHAT.AccountId,
      saveToTranscript: CHAT.AccountId,
      showOnCreate: true,
      fieldMap: [
        {
          fieldName: CHAT.Customer_name,
          label: CHAT.customer_name,
          isExactMatch: true,
          doCreate: false,
          doFind: true
        },
        {
          fieldName: CHAT.PersonMobilePhone,
          label: CHAT.customer_mobile,
          isExactMatch: false,
          doCreate: false,
          doFind: true
        }
      ]
    };
    SalesforceSnapIns.addPrechatEntity(accountEntity);

    var contactEntity = {
      name: CHAT.Contact,
      linkToEntityName: CHAT.Contact,
      linkToEntityField: CHAT.ContactId,
      saveToTranscript: CHAT.ContactId,
      showOnCreate: true,
      fieldMap: [
        {
          fieldName: CHAT.Customer_name,
          label: CHAT.customer_name,
          isExactMatch: true,
          doCreate: false,
          doFind: true
        },
        {
          fieldName: CHAT.MobilePhone,
          label: CHAT.customer_mobile,
          isExactMatch: false,
          doCreate: false,
          doFind: true
        }
      ]
    };
    SalesforceSnapIns.addPrechatEntity(contactEntity);


    SalesforceSnapIns.determineAvailability(function (available) {
      if (available) {
        SalesforceSnapIns.openLiveAgentChat(function () {
        }, function () {
        });
      } else {
        object.common.agentUnavailable();
      }
    }, function (err) {
    });
  }

  //..logout chat here//
  chatOffSection() {
    let SalesforceSnapIns = window.cordova.plugins.SalesforceSnapIns;
    SalesforceSnapIns.dismissChat(function (data) {
    }, function (err) {
      console.error(err);
    });
  }

}


