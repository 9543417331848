import { TimestampConvertPipe } from "src/app/pipes/timestamp-convert.pipe";
import * as moment from 'moment';
export class RevenueTimes {
    startDate: any;
    endDate: any;
    startTimeHour: any;
    startTimeMinute: any;
    startTimePeriod: any;
    endTimeHour: any;
    endTimeMinute: any;
    endTimePeriod: any;
    days: any;
}

export class RevenueGet {

    public fullMenu: RevenueTimes;
    public byDelivery = {};
    public menuWidget = [];


    

    constructor(attributes?: any) {
        console.log("attribute from constructor", attributes)
        Object.assign(this, attributes);
        this.fullMenu = this.factorTime(this.fullMenu, 'percentageSelectedMenu');
        this.byDelivery = this.factorTime(this.byDelivery, 'percentageSelectedDelivery');
        this.factorMenuWidgets();
        console.log("fullMenu", this.fullMenu);
        console.log("Revenue object:: get >", this);
    }

    private factorMenuWidgets() {
        console.log("factorMenuWidgets");
        let temp = [];
        if (this.menuWidget.length) {
            this.menuWidget.forEach(e => {
                console.log("e value", e);
                temp.push(this.factorTime(e, 'percentageSelectedWidget'));
            });
        }
        console.log("temp value in factormenuwidget", temp);
        this.menuWidget = temp;
        console.log("data inside factormenuwidget", this.menuWidget);
    }

    private factorTime(timeObject: any, percentageField: string, widgetId? : any, uniqueId? : any) {
        console.log("factor time called", timeObject);
        let temp: any = {};
        if (timeObject) {
            if ("days" in timeObject) {
                temp.days = timeObject.days;
            }
            let startTime = moment(timeObject.startTime, "hh:mm A");
            let endTime = moment(timeObject.endTime, "hh:mm A");
            temp.startDate = timeObject.startDate;
            temp.endDate = timeObject.endDate;
            temp.startTimeHour = startTime.format("hh");
            temp.startTimeMinute = startTime.format("mm");
            temp.startTimePeriod = startTime.format("A")
            temp.endTimeHour = endTime.format("hh");
            temp.endTimeMinute = endTime.format("mm");
            temp.endTimePeriod = endTime.format("A");
            temp[percentageField] = timeObject[percentageField];
            temp.uniqueId = timeObject.uniqueId;
            if ('widgetId' in timeObject) {
                temp.selectedWidget = timeObject.widgetId;
            }
        }
        return temp;
    }

}

export class RevenuePost {
    public fullMenu: any;
    public byDelivery: any;
    public menuWidget = [];
    data: any = {};

    constructor(attributes?: any) {
        if (attributes) {
            console.log("constructor");
            this.data = attributes;
            Object.assign(this, attributes);
            console.log("Revenue object:: post>", this);
        }
    }

    private makeTimeString(timeObject: any, percentageField: any): Object {
        console.log("makeTimeString");
        let conversion: TimestampConvertPipe = new TimestampConvertPipe();
        let temp: any = {};
        console.log("factor time called for post", timeObject);
        if ("days" in timeObject) {
            temp.days = timeObject.days;
        }
        temp.startTime = moment(conversion.transform(timeObject, 'start'), "h:mm:ss A").format("HH:mm:ss");
        temp.endTime = moment(conversion.transform(timeObject, 'end'), "h:mm:ss A").format("HH:mm:ss");
        temp.startDate = moment(timeObject.startDate).format("YYYY-MM-DD");
        temp.endDate = moment(timeObject.endDate).format("YYYY-MM-DD");
        temp[percentageField] = timeObject[percentageField];
        temp.widgetId = timeObject.selectedWidget;
        temp.uniqueId = timeObject.uniqueId;
        console.log("widgetid", timeObject.selectedWidget);
        if ('widgetId' in timeObject) {
            temp['widgetId'] = timeObject.widgetId;
        }
        return temp;
    }

    deserialize() {
        console.log("deserialize");
        if (this.fullMenu) {
            this.data.fullMenu = this.makeTimeString(this.fullMenu, 'percentageSelectedMenu');
        }
        if (this.menuWidget.length) {
            this.data.menuWidget = this.menuWidgetData();
        }
        if (this.byDelivery) {
            this.data.byDelivery = this.makeTimeString(this.byDelivery, 'percentageSelectedDelivery');
        }
        return this.data;
    }

    private menuWidgetData() {
        console.log("menuWidgetData")
        let temp: Array<any> = [];
        this.menuWidget.forEach(e => {
            console.log("e -->>" , e);
            temp.push(this.makeTimeString(e, 'percentageSelectedWidget'));
        });
        console.log("menuWidget", temp)
        return temp;
    }

}
