import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FEEDBACK_STAR } from 'src/app/app.constants';
@Component({
  selector: 'app-feedback-star',
  templateUrl: './feedback-star.component.html',
  styleUrls: ['./feedback-star.component.scss'],
})
export class FeedbackStarComponent implements OnInit {
  
  change: any;
  starRating: any;

  constructor(
    private modalCtrl: ModalController,
    navParams: NavParams,
  ) {
    this.starRating = navParams.get(FEEDBACK_STAR.setRating);
  }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onItemChange(value) {
    console.log("value",value)
    this.change = value.target.value;
    console.log("value",this.change)
  }

  onClick() {
    let dataForFeedbackHistory = {
      "starRating": this.change,
    }
    this.modalCtrl.dismiss({
      "setstarData": dataForFeedbackHistory,
    });
  }
}
